'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import Modal from 'react-modal';

import Select from '../../pro/components/Widgets/Select.react';

import { inflectUnitOfMeasure } from '../../utils/Math';
import { roundForHumans } from '../../utils/Math';

import allUnits from '../../tables/units';

import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';

import './FoodUnitsSelector.scss';
import Analytics from '../../utils/Analytics';

const allServingOpts = [
    { label: '¼ - Quarter Serving ', value: 0.25 },
    { label: '½ - Half Serving ', value: 0.5 },
    { label: '¾ - Three Quarters Serving', value: 0.75 },
    { label: '1 - Single Serving', value: 1 },
    { label: '1½ - Large Serving', value: 1.5 },
    { label: '2 - Double Serving', value: 2 },
    { label: '3 - Triple Serving', value: 3 },
];

const BLANK_UNIT = 'item';

export default class FoodUnitsSelector extends Component {
    static propTypes = {
        food: PropTypes.object,
        meal: PropTypes.object,
        disabled: PropTypes.bool,
        disableSaveBtn: PropTypes.func,
        showAbove: PropTypes.bool,
        onChangeAmount: PropTypes.func,
        selectClassName: PropTypes.string,
        canUpdateParent: PropTypes.bool,
        errorMessage: PropTypes.string,
        defaultSizeHeader: PropTypes.string,
        unverified: PropTypes.bool,
    };

    static defaultProps = {
        showAbove: false,
        canUpdateParent: true,
        selectClassName: 'el-select-container',
        defaultSizeHeader: "Size",
        unverified: false,
    };

    constructor(props) {
        super(props);

        const { units_mode = 'english' } = UserStore.getUser() || {};
        const { density, estimated } = this.getFoodDensity(props.food, []);

        this.state = {
            ...this.getStateFromProps(props, units_mode),

            units_mode,
            units: this.computeUnitsList(props.food, props.meal, [], density, estimated, units_mode),
            custom_units: [],
            isConfirmGramsOpen: false,
            confirmValue: '',
            for_food: props.food ? props.food.uuid : null,
            error: false,
        };

        this.resetInihibitUpdate = debounce(this.resetInihibitUpdate, 250);
    }

    inhibitUpdate = false;

    resetInihibitUpdate = () => {
        this.inhibitUpdate = false;
    };

    componentDidMount = () => {
        this.syncAssets();
        // this.updateParent();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.inhibitUpdate) {
            return;
        }

        const { for_food, units_mode } = this.state;
        const { food, meal } = this.props;

        // If the food or the meal object changed, reset our state.
        const isDifferentFood = (nextProps.food && nextProps.food.uuid !== for_food);
        const hasMealChanged = (nextProps.meal && !meal) ||
                               (!nextProps.meal && meal) ||
                               (nextProps.meal?.logged_amount != meal?.logged_amount) ||
                               (nextProps.meal?.logged_unit != meal?.logged_unit);

        if  (isDifferentFood || hasMealChanged) {
            this.setState({
                ...this.getStateFromProps(nextProps, units_mode),
                for_food: nextProps.food.uuid
            }, this.syncAssets);
        }
    };

    computeUnitsList = (food, meal, custom_units, density, estimated, units_mode) => {
        const units = (food.units || [])
            .concat(custom_units.map((unit) => Object.assign(unit, { confirm: true })))
            .filter(({ description }) => description?.trim().toLowerCase() !== 'item')
            .map(({ description, plural, ...rest }) => {
                description = description ? description.trim() : description;
                plural = plural ? plural.trim() : plural;
                return { description, plural, ...rest };
            });

        allUnits[food?.language || 'en'].forEach(({ description, plural, alts = [], grams, milliliters }) => {
            // Can we find this conversion in our units list?
            const unit = units.find((unit) => {
                return alts.includes((unit.description || '').trim().toLowerCase());
            });

            // Great! We don't need to do anything else with this unit.
            if (unit) {
                return;
            }

            if (food.serving_unit !== 'g' && food.milliliters_per_serving) {
                if (milliliters && density) {
                    units.push({
                        description,
                        plural,
                        amount: 1,
                        milliliters,
                        grams: milliliters * density,
                        confirm: estimated,
                    });
                } else if (grams && density) {
                    units.push({
                        description,
                        plural,
                        amount: 1,
                        grams,
                        milliliters: grams / density,
                        confirm: estimated,
                    });
                } else if (milliliters && !density) {
                    // push without a grams or milliliters. If selected, we'll prompt for a weight
                    units.push({ description, plural, amount: 1, milliliters });
                } else if (grams && !density) {
                    // Do nothing
                } else {
                    units.push({ description, plural, amount: 1 });
                }
            } else if (food.serving_unit !== 'ml' && food.grams_per_serving) {
                if (grams && density) {
                    units.push({
                        description,
                        plural,
                        amount: 1,
                        grams,
                        milliliters: grams / density,
                        confirm: estimated,
                    });
                } else if (milliliters && density) {
                    units.push({ description, plural, amount: 1, grams: milliliters * density, confirm: estimated });
                } else if (grams && !density) {
                    units.push({ description, plural, amount: 1, grams });
                } else if (milliliters && !density) {
                    // Do nothing
                }
            } else {
                units.push({ description, plural, amount: 1 });
            }
        });

        // Do we have a "serving" unit already? If not, add one.
        const servingUnit = units.find((unit) => ['servings', 'serving'].includes(unit.description.toLowerCase()));

        if (!servingUnit && food.serving_unit !== 'g' && food.milliliters_per_serving) {
            units.push({
                description: 'serving',
                plural: 'servings',
                amount: 1,
                milliliters: food.milliliters_per_serving,
            });
        } else if (!servingUnit && food.serving_unit !== 'ml' && food.grams_per_serving) {
            units.push({
                description: 'serving',
                plural: 'servings',
                amount: 1,
                grams: food.grams_per_serving,
            });
        }

        // How about the currently selected logged_unit? Is it already in the list?
        if (meal) {
            const { logged_amount, logged_unit, logged_grams, logged_milliliters } = meal;
            let selectedUnit = units.find((unit) => unit.description === logged_unit);

            if (!selectedUnit) {
                units.push({
                    description: logged_unit,
                    amount: logged_amount,
                    grams: logged_grams,
                    milliliters: logged_milliliters,
                });
            }
        }

        return units;
    };

    syncAssets = async () => {
        let { food, meal } = this.props;
        let { custom_units, units_mode } = this.state;

        if (!food) {
            return;
        }

        if (this.loadedUnitsFor !== food.uuid) {
            this.loadedUnitsFor = food.uuid;
            custom_units = await this.loadCustomUnitsForFood(food);
        }

        const { density, estimated } = this.getFoodDensity(food, custom_units);

        const units = this.computeUnitsList(food, meal, custom_units, density, estimated, units_mode);

        this.setState({ units, custom_units, density, estimated });
    };

    loadCustomUnitsForFood = async (food) => {
        const customUnitsURL = UserStore.getLinks().self + '/units/' + food.uuid;
        const response = await AuthStore.fetch(customUnitsURL);

        return response.elements ? response.elements : [];
    };

    getStateFromProps = (props, units_mode) => {
        let { logged_portion, logged_unit, logged_amount } = props.meal || {};
        let { food } = props;

        if (typeof logged_amount == 'undefined' && typeof logged_unit === 'undefined') {
            if (food && food.default_unit) {
                logged_unit = food.default_unit.description;
                logged_amount = food.default_unit.amount;
            } else if (food && (food.grams_per_serving || food.milliliters_per_serving)) {
                logged_unit = 'serving';
                logged_amount = 1;
            }
        } else if (typeof logged_amount == 'undefined') {
            logged_amount = '';
        }

        let entry_mode = units_mode;

        if (logged_amount && logged_amount >= 21) {
            entry_mode = 'metric';
        }

        return {
            logged_portion,
            logged_unit,
            logged_amount,
            entry_mode,
        };
    };

    updateParent = () => {
        const { onChangeAmount, canUpdateParent } = this.props;
        let { units, logged_portion, logged_unit, logged_amount } = this.state;

        if (canUpdateParent) {
            this.inhibitUpdate = true;

            // Parse as a float
            logged_amount = parseFloat(logged_amount) || 0;

            // Figure out the amount of grams or milliliters from the unit and the amount.
            let unit = units.find((unit) => unit.description === logged_unit);

            if (unit) {
                onChangeAmount(
                    logged_portion,
                    unit.description,
                    logged_amount,
                    unit.grams ? (unit.grams / unit.amount) * logged_amount : null,
                    unit.milliliters ? (unit.milliliters / unit.amount) * logged_amount : null,
                    unit
                );

                this.resetInihibitUpdate();
                return;
            }

            onChangeAmount(logged_portion, logged_unit, logged_amount, null, null);
            this.resetInihibitUpdate();
        }
    };

    onChangeFoodUnit = (logged_unit) => {
        const { units, units_mode, density } = this.state;
        const { food } = this.props;

        let confirmValue;

        if (logged_unit == BLANK_UNIT) {
            logged_unit = '';
        }

        // This should always succeed, bug if it doesn't
        const unit = units.find((unit) => unit.description === logged_unit);

        // Do we need to ask for confirmation?
        if (food.serving_unit === 'ml' && (!unit.milliliters || unit.confirm)) {
            // Logged unit will probably be a mass-based unit, pounds, ounce, kg.
            // We need to calculate density, so we ask for the weight of 1 <cup> of the liquid.
            confirmValue =
                units_mode === 'english'
                    ? Math.round(((236.588 * density) / 28.3495) * 100) / 100
                    : Math.round(236.588 * density * 100) / 100;

            this.setState({
                isConfirmMlOpen: true,
                confirmUnit: logged_unit,
                confirmValue,
            });

            Analytics.enterWeight({ 'Food UUID': food.uuid, 'Food Name': food.name, 'Selected Unit': logged_unit });
        } else if ((food.serving_unit === 'g' && !unit.grams) || unit.confirm) {
            confirmValue =
                units_mode === 'english'
                    ? Math.round((unit.grams / 28.3495) * 100) / 100 || '' // convert to grams
                    : unit.grams || '';

            this.setState({
                isConfirmGramsOpen: true,
                confirmUnit: logged_unit,
                confirmValue: unit.grams,
            });

            Analytics.enterWeight({ 'Food UUID': food.uuid, 'Food Name': food.name, 'Selected Unit': logged_unit });
        } else {
            this.setState({ logged_unit, isConfirmGramsOpen: false, isConfirmMlOpen: false }, this.updateParent);
        }
    };

    onChangeFoodAmount = (ev) => {
        const { disableSaveBtn } = this.props;

        let logged_amount = ev.target.value;
        const parsedLoggedAmount = parseFloat(logged_amount);

        if (isNaN(parsedLoggedAmount) || parsedLoggedAmount <= 0) {
            this.setState({ logged_amount, error: true }, this.updateParent);
            disableSaveBtn && disableSaveBtn(true);
            return;
        }

        this.setState({ logged_amount: parsedLoggedAmount, error: false }, this.updateParent);
        disableSaveBtn && disableSaveBtn(false);
    };

    onChangeLoggedWhole = (logged_whole) => {
        let { logged_amount, units_mode } = this.state;

        const whole = Math.floor(logged_amount),
            fraction = Math.round((logged_amount - whole) * 8) / 8;

        if (logged_whole >= 21) {
            units_mode = 'metric';
        }

        this.setState({ logged_amount: parseInt(logged_whole) + (fraction || 0), units_mode }, this.updateParent);
    };

    onChangeLoggedFraction = (logged_fraction) => {
        const { logged_amount } = this.state;

        const whole = Math.floor(logged_amount),
            fraction = Math.round((logged_amount - whole) * 8) / 8;

        this.setState({ logged_amount: (whole || 0) + parseFloat(logged_fraction) }, this.updateParent);
    };

    getFoodDensity = (food, custom_units = []) => {
        if (!food) {
            return { density: null, estimate: false };
        }

        // If we have both grams and milliliters per serving, no need to estimate or average.
        if (food.grams_per_serving && food.milliliters_per_serving) {
            return { density: food.grams_per_serving / food.milliliters_per_serving, estimate: false };
        }

        const availableUnits = (food.units || []).concat(custom_units || []);

        if ((food.units || []).length + (custom_units || []).length <= 0) {
            return { density: null, estimate: false };
        }

        const densities = [];

        const processMassUnit = ({ description = '', amount = 1, milliliters }) => {
            allUnits[food.language].forEach(({ alts, grams }) => {
                if (amount && milliliters && alts.includes(description.toLowerCase())) {
                    densities.push(grams / (milliliters / amount));
                }
            });
        };

        const processVolumeUnit = ({ description = '', amount = 1, grams }) => {
            allUnits[food.language].forEach(({ alts, milliliters }) => {
                if (amount && milliliters && alts.includes(description.toLowerCase())) {
                    densities.push(grams / amount / milliliters);
                }
            });
        };

        if (food.serving_unit === 'ml') {
            (food.units || []).forEach(processMassUnit);
            (custom_units || []).forEach(processMassUnit);
        } else {
            (food.units || []).forEach(processVolumeUnit);
            (custom_units || []).forEach(processVolumeUnit);
        }

        // Return the average of all volume unit conversions
        if (densities.length > 0) {
            return {
                density: densities.reduce((density, carry) => density + carry, 0) / densities.length,
                estimate: true,
            };
        }

        return { density: null, estimate: false };
    };

    onChangeConfirmValue = (ev) => {
        this.setState({ confirmValue: ev.target.value });
    };

    closeModal = () => {
        this.setState({ isConfirmGramsOpen: false, isConfirmMlOpen: false });
    };

    saveCustomUnit = (food, unit, units) => {
        // Save the custom unit to the UGC endpoint
        const customUnitsURL = UserStore.getLinks().self + '/units/' + food.uuid;

        return AuthStore.fetch(customUnitsURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=unit/1' },
            body: JSON.stringify(unit),
        }).then(
            (response) => {
                const existing = units.find((cu) => cu.description === response.description);

                if (existing) {
                    const i = units.indexOf(existing);
                    units[i] = response;
                } else {
                    units.push(response);
                }

                return units;
            },
            (error) => {
                this.setState({ confirmError: (error && error.message) || error || 'unknown error' });
            }
        );
    };

    confirmSaveMilliliters = async () => {
        const { units_mode, confirmUnit, confirmValue } = this.state;
        const { food, meal } = this.props;

        let value = parseFloat(confirmValue);

        if (!value || isNaN(value) || value <= 0) {
            this.setState({
                confirmError:
                    units_mode === 'metric'
                        ? 'Please enter a mass for this unit.'
                        : 'Please enter a weight for this unit.',
            });
            return;
        }

        // Find the conversion factor.
        const unitDefinition = allUnits[food.language].find((au) => au.description === confirmUnit);

        // From the answer, we determine the g/ml and then use that to
        const density = units_mode === 'metric' ? 236.588 / value : 236.588 / (value * 28.3495);

        const newUnit = {
            description: confirmUnit,
            amount: 1,
            milliliters: unitDefinition.grams / density,
        };

        const custom_units = await this.saveCustomUnit(food, newUnit, this.state.custom_units);

        const units = this.computeUnitsList(food, meal, custom_units, density, true);

        this.setState(
            {
                units,
                logged_unit: newUnit.description,
                custom_units,
                working: false,
                isConfirmMlOpen: false,
            },
            this.updateParent
        );
    };

    confirmSaveGrams = async () => {
        const { units_mode, confirmUnit, confirmValue } = this.state;
        const { food, meal } = this.props;

        let value = parseFloat(confirmValue);

        if (!value || isNaN(value) || value <= 0) {
            this.setState({
                confirmError:
                    units_mode === 'metric'
                        ? 'Please enter a mass for this unit.'
                        : 'Please enter a weight for this unit.',
            });
            return;
        }

        const unitDefinition = allUnits[food.language].find((au) => au.description === confirmUnit);

        const density = unitDefinition && unitDefinition.milliliters ? value / unitDefinition.milliliters : null;

        const newUnit = {
            description: confirmUnit,
            amount: 1,
            grams: units_mode === 'metric' ? value : value * 28.3495,
        };

        this.setState({ working: true });

        const custom_units = await this.saveCustomUnit(food, newUnit, this.state.custom_units);

        const units = this.computeUnitsList(food, meal, custom_units, density, true);

        this.setState(
            {
                units,
                logged_unit: newUnit.description,
                custom_units,
                working: false,
                isConfirmGramsOpen: false,
            },
            this.updateParent
        );
    };

    renderInModal = (content, onSave) => {
        return (
            <Modal
                isOpen={true}
                closeModal={() => false}
                contentLabel="Confirm weight"
                className="el-modal el-modal3 el-modal3-centered"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <section className="el-modal-container el-modal3-container food-units-confirm-amount">
                    {content}

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.closeModal}>
                            cancel
                        </button>
                        <button className="el-modal-ok-btn" onClick={onSave}>
                            save
                        </button>
                    </footer>
                </section>
            </Modal>
        );
    };

    renderConfirmModal = () => {
        const { food } = this.props;
        const { units_mode, confirmValue, confirmUnit, confirmError, isConfirmMlOpen, isConfirmGramsOpen } = this.state;

        const searchGoogleUrl =
            'https://www.google.com/search?q=weight+of+one+' +
            encodeURIComponent(confirmUnit) +
            '+of+' +
            encodeURIComponent(food.name);

        if (isConfirmGramsOpen) {
            return this.renderInModal(
                <div className="el-modal-body-container el-modal3-body-container el-fonts">
                    <p className="p3">
                        To calculate nutrition and to build your grocery list, please enter the{' '}
                        {units_mode === 'metric' ? 'mass' : 'weight'}.
                    </p>

                    <h5>
                        1 {confirmUnit} of {food.pretty_name || food.name || food.title} weighs:
                    </h5>
                    <div className="el-labeled-input el-input-with-unit">
                        <div className="el-input-container">
                            <input type="number" value={confirmValue || ''} onChange={this.onChangeConfirmValue} />
                            <em>{units_mode === 'metric' ? 'g' : 'oz.'}</em>
                        </div>
                    </div>

                    <a target="_blank" rel="noreferrer" className="el-text-btn" href={searchGoogleUrl}>
                        look up weight
                    </a>

                    {confirmError ? <p className="error">{confirmError}</p> : null}
                </div>,
                this.confirmSaveGrams
            );
        }

        if (isConfirmMlOpen) {
            return this.renderInModal(
                <div className="el-modal-body-container el-modal3-body-container el-fonts">
                    <p className="p3">
                        To calculate nutrition and to build your grocery list, please enter the{' '}
                        {units_mode === 'metric' ? 'mass' : 'weight'}.
                    </p>

                    <h5>1 cup of {food.pretty_name || food.name || food.title} weighs:</h5>
                    <div className="el-labeled-input el-input-with-unit">
                        <div className="el-input-container">
                            <input type="number" value={confirmValue || ''} onChange={this.onChangeConfirmValue} />
                            <em>{units_mode === 'metric' ? 'g' : 'oz.'}</em>
                        </div>
                    </div>

                    <a target="_blank" rel="noreferrer" className="el-text-btn" href={searchGoogleUrl}>
                        look up weight
                    </a>

                    {confirmError ? <p className="error">{confirmError}</p> : null}
                </div>,
                this.confirmSaveMilliliters
            );
        }

        return;
    };

    onChangeServings = (logged_amount) => {
        this.setState({ logged_amount }, this.updateParent);
    };

    renderUnitLabel = (logged_amount, unit) => {
        const { units_mode } = this.state;
        const { food } = this.props;

        let label = inflectUnitOfMeasure(logged_amount, unit);

        if (unit.description === 'serving' && Boolean(unit.grams || unit.milliliters)) {
            if (units_mode === 'english') {
                label =
                    food.serving_unit === 'ml' || unit.milliliters
                        ? label + ' (' + roundForHumans(((logged_amount * unit.milliliters) / 29.5735)) + ' fl oz.)'
                        : label + ' (' + roundForHumans(((logged_amount * unit.grams) / 28.3495)) + ' oz)';
            } else {
                label =
                    food.serving_unit === 'ml' || unit.milliliters
                        ? label + ' (' + roundForHumans((logged_amount * unit.milliliters)) + ' ml)'
                        : label + ' (' + roundForHumans((logged_amount * unit.grams)) + ' g)';
            }
        }

        return label || BLANK_UNIT;
    };

    render() {
        const { food, disabled, showAbove, selectClassName, errorMessage, defaultSizeHeader, unverified } = this.props;
        const { units_mode, logged_unit, logged_amount, units, error } = this.state;

        const unitToOpt = (unit) => ({
            label: this.renderUnitLabel(logged_amount, unit),
            value: (unit.description || BLANK_UNIT).trim(),
        });

        let unitOpts = units.map(unitToOpt);

        const amountError = errorMessage && errorMessage.includes("amount");
        const sizeError = errorMessage && errorMessage.includes("size");
        const selectedUnit = units.find(unit => unit.description === logged_unit);

        // Sort units alphabetically
        unitOpts.sort((a, b) => a.label.localeCompare(b.label));

        const whole = Math.floor(logged_amount),
            fraction = Math.round((logged_amount - whole) * 8) / 8;

        const servingOpts = allServingOpts.map(({ value, label }) => {
            if (food.milliliters_per_serving && units_mode === 'english') {
                label += ' (' + roundForHumans((value * food.milliliters_per_serving) / 29.5735) + ' fl oz.)';
            } else if (food.milliliters_per_serving && units_mode === 'metric') {
                label += ' (' + roundForHumans(value * food.milliliters_per_serving) + ' ml)';
            } else if (food.grams_per_serving && units_mode === 'english') {
                label += ' (' + roundForHumans((value * food.grams_per_serving) / 28.3495) + ' oz)';
            } else if (food.grams_per_serving && units_mode === 'metric') {
                label += ' (' + roundForHumans(value * food.grams_per_serving) + ' g)';
            }

            return { value, label };
        });
        const dataError =
            !unverified &&
            !(selectedUnit?.grams || selectedUnit?.milliliters) &&
            selectedUnit?.description != 'serving';

        return (
            <div className="food-unit-selector">
                {unitOpts.length > 0 ? (
                    <div className="unit-with-amount">
                        <div className="el-labeled-input food-amount" data-error={dataError}>
                            <label htmlFor="food-amount-input">Amount</label>

                            <input
                                id="food-amount-input"
                                type="number"
                                min="0"
                                max="99999"
                                step={0.25}
                                disabled={disabled}
                                value={logged_amount}
                                onChange={this.onChangeFoodAmount}
                                onClick={(e) => e.target.select()}
                                data-error={amountError}
                            />
                        </div>

                        <div className="el-labeled-input food-unit" data-error={dataError}>
                            <label>{defaultSizeHeader}</label>
                            <Select
                                dropdownIcon="icon-down-arrow"
                                showAbove={showAbove}
                                disabled={disabled}
                                className={selectClassName}
                                placeholder={defaultSizeHeader}
                                options={unitOpts}
                                value={logged_unit || BLANK_UNIT}
                                onChange={this.onChangeFoodUnit}
                                data-error={sizeError}
                            />
                        </div>
                    </div>
                ) : null}

                {unitOpts.length <= 0 ? (
                    <div>
                        <Select
                            showAbove={showAbove}
                            disabled={disabled}
                            className={selectClassName}
                            placeholder="Choose servings"
                            options={servingOpts}
                            value={logged_amount}
                            onChange={this.onChangeServings}
                        />
                    </div>
                ) : null}
                {error || errorMessage ? <div className="error-message">{errorMessage || "Please enter a valid amount."}</div> : null}
                {this.renderConfirmModal()}
            </div>
        );
    }
}
