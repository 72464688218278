'use strict';

import uuid from 'uuid';
import store from 'store';
import moment from 'moment';
import indexBy from 'lodash.indexby';

import { fetchDocumentsById } from './Content';
import { flattenIngredients, getFoodIdsFromIngredients } from './Recipe';
import { getConfig } from './Env';
import { getGroceriesForMeals } from './Grocery';
import { isSunbasketFood } from './Sunbasket';

export function isScalable(primary){
    return primary.content.servings && (primary.content.type !== "food" || isSunbasketFood(primary.food));
}

export function dateCompare(a, b) {
    let aDate = moment(a.date);
    let bDate = moment(b.date);

    if (bDate.isBefore(a.date)) {
        return 1;
    }

    if (aDate.isBefore(b.date)) {
        return -1;
    }

    return 0;
}

const mealNames = ['Breakfast', 'Lunch', 'Dinner', 'Snack'];

export function mealSortByCreated(a, b) {
    return new Date(b.created) - new Date(a.created);
}

export function mealSortCompare(a, b, debug = false) {
    // #0 sort by deleted go last
    if (!a.deleted && b.deleted) return -1;
    if (a.deleted && !b.deleted) return 1;

    // #1 Sort by Date
    const aDate = moment(a.date),
        bDate = moment(b.date);
    if (!aDate.isSame(bDate, 'day')) {
        return aDate - bDate;
    }

    // #2 Sort by meal type
    let ai = mealNames.indexOf(a.meal),
        bi = mealNames.indexOf(b.meal);

    if (ai != -1 && bi == -1) return -1;
    if (ai == -1 && bi != -1) return 1;

    if (ai > bi) return 1;
    if (ai < bi) return -1;

    // #3 Sort non-sides above sides, even if they're not recipes
    if (!a.side_dish && b.side_dish) return -1;
    if (a.side_dish && !b.side_dish) return 1;

    // #4 - fresh > *
    if (a.meal_type === 'fresh' && b.meal_type !== 'fresh') return -1;
    if (a.meal_type !== 'fresh' && b.meal_type === 'fresh') return 1;

    // #5 - leftovers > *(except fresh)
    if (a.meal_type === 'leftover' && b.meal_type !== 'leftover') return -1;
    if (a.meal_type !== 'leftover' && b.meal_type === 'leftover') return 1;

    // #6 Sunbasket foods come before other foods. Only sunbasket foods should have participants

    if (a.meal_type === "food" && b.meal_type === "food") {
        const aHasParticipants = a.participants && a.participants.trim() !== "";
        const bHasParticipants = b.participants && b.participants.trim() !== "";

        if (aHasParticipants && !bHasParticipants) {
            return -1;
        }
        if (!aHasParticipants && bHasParticipants) {
            return 1;
        }
    }

    return 0;
}

export function updateMealLeftovers(meal, allMeals, recipe, profile, leftoverDates = [], alwaysLeftover = false) {
    let dirtyMeals = [meal];

    if (!profile.preferences.leftovers_enabled) {
        return { dirtyMeals, toRemove: [], leftovers: [] };
    }

    // If the meal is already a leftover, we have nothing else to do.
    if (!['food', 'fresh'].includes(meal.meal_type)) {
        return { dirtyMeals, toRemove: [], leftovers: [] };
    }

    let tags = (recipe && recipe.tags) || [];

    // Do we have a no leftovers tag? Don't bother creating leftovers records.
    if (tags.indexOf('No Leftovers') != -1) {
        return { dirtyMeals, toRemove: [], leftovers: [] };
    }

    // Find all leftovers records, sort by earliest to latest
    let oldLeftovers = allMeals.filter((m) => m.parent_uuid == meal.uuid).sort(dateCompare);

    // Start counting how many servings we've accounted for
    let participants = getParticipantsForMeal(meal, profile);

    // Use the original recipe servings or if its not loaded yet, the serving count from the plan.
    let totalServings = (meal.scaling || 1) * ((recipe && recipe.servings) || meal.servings || 1);

    const neededPerMeal = participants.reduce((total, member) => total + (member.portion || 1), 0);
    const totalLeftovers = Math.floor(totalServings / neededPerMeal) - 1;

    const leftovers = [];

    let i = 1;
    while (i <= totalLeftovers && i <= 4) {
        // We can either re-use a leftover, or we can create one.
        let leftover;
        let leftoverDate = moment(meal.date).add(i, 'day');

        // Is this one of our dates in leftoverDates? If not, don't add a leftover for it.
        if (!leftoverDates.find((d) => leftoverDate.isSame(d, 'day')) && !alwaysLeftover) {
            i++;
            continue;
        }

        if (oldLeftovers.length) {
            leftover = Object.assign({}, oldLeftovers.shift());
            leftover.date = leftoverDate.format('YYYY-MM-DD');
            leftover.meal = meal.meal;
            leftover.participants = meal.participants;
            leftover.scaling = meal.scaling;
            leftover.deleted = false;
        } else {
            leftover = {
                ...meal,
                uuid: uuid.v4(),
                meal_type: 'leftover',
                date: leftoverDate.format('YYYY-MM-DD'),
                parent_uuid: meal.uuid,
            };
        }

        leftovers.push(leftover);
        dirtyMeals.push(leftover);

        i++;
    }

    return { dirtyMeals, toRemove: oldLeftovers, leftovers };
}

export function createMealsForPlan(plan, dateStart, participants, planMarker) {
    let meals = [];
    let created = moment().format();

    // This will make sure first meals of the plan will always start on the day that
    // the user picked to start the plan.
    let minOffset = 999;

    plan.items.forEach((item) => {
        if (item.offset < minOffset) {
            minOffset = item.offset;
        }
    });

    if (minOffset > 50) {
        minOffset = 0;
    }

    // Get a list of all the fresh meals, and then a list of all the leftovers. We'll do the fresh meals first
    const fresh = plan.items.filter((item) => item.meal_type === 'fresh'),
        leftovers = plan.items.filter((item) => item.meal_type === 'leftover'),
        rest = plan.items.filter((item) => !['fresh', 'leftover'].includes(item.meal_type));

    const freshIndex = {};
    const mealTypes = [];

    fresh
        .concat(leftovers)
        .concat(rest)
        .forEach((item) => {
            const {
                meal_type = 'fresh',
                scaling = 1,
                participants,
                recipe_uuid,
                details_uuid,
                food_uuid,
                ...rest
            } = item;

            let meal = {
                ...rest,
                uuid: uuid.v4(),
                created,
                meal_type,
                recipe_uuid,
                details_uuid,
                food_uuid,
                scaling,
                participants,
                meal: item.meal,
                date: moment(dateStart)
                    .add(item.offset - minOffset, 'day')
                    .format('YYYY-MM-DD'),
                plan_uuid: planMarker.uuid,
                plan_date: moment(dateStart).format('YYYY-MM-DD'),
            };

            if (item.meal_type === 'fresh' && item.id) {
                freshIndex[item.id] = meal;
            }

            if (item.meal_type === 'leftover' && freshIndex[item.parent]) {
                meal.parent_uuid = freshIndex[item.parent].uuid;
            }

            if (!mealTypes.includes(meal.meal)) {
                mealTypes.push(meal.meal);
            }

            meals.push(meal);
        });

    // Loop through all the meals again and pull out the maximum date
    let maxDate = moment.max(meals.map((m) => moment(m.date)));

    return { meals, maxDate, mealTypes };
}

export function getAssetsForMeals(meals, extraUuids = []) {
    return new Promise((resolve, reject) => {
        // 1. Load list of top-level recipe and details for these meals
        // 2. Load list of subrecipes & subdetails for these meals (merge with recipe & details lists)
        // 3. Load list of all foods for these meals

        // extract a list of details uuids, subrecipe and subdetails IDs.
        let firstUuids = [];

        meals.forEach((m) => {
            const type = m.type || m.meal_type;

            if ((type === 'leftover' || type === 'fresh') && m.recipe_uuid) {
                firstUuids.push(m.recipe_uuid);
                firstUuids.push(m.details_uuid);
                return;
            }

            if (m.food_uuid) firstUuids.push(m.food_uuid);
            if (m.location_uuid) firstUuids.push(m.location_uuid);
        });

        if (extraUuids && extraUuids.length) {
            firstUuids = firstUuids.concat(extraUuids);
        }

        // First load the details
        fetchDocumentsById(firstUuids).then((documents) => {
            let recipes = indexBy(
                documents.filter((d) => d && d.type == 'recipe'),
                'uuid'
            );
            let details = indexBy(
                documents.filter((d) => d && d.type == 'recipe_details'),
                'uuid'
            );
            let foods = indexBy(
                documents.filter((d) => d && d.type == 'food'),
                'uuid'
            );
            let locations = indexBy(
                documents.filter((d) => d && d.type == 'brand_location'),
                'uuid'
            );

            // Load all subrecipe and subdetails uuids
            let secondUuids = [];
            Object.keys(recipes).forEach((id) => {
                if (recipes[id] && recipes[id].merchant && recipes[id].merchant.uuid) {
                    secondUuids.push(recipes[id].merchant.uuid);
                }

                if (!recipes[id].subrecipes) {
                    return;
                }

                recipes[id].subrecipes.forEach((sr) => {
                    secondUuids.push(sr.recipe);
                    secondUuids.push(sr.details);
                });
            });

            Object.keys(foods).forEach((id) => {
                if (foods[id].brand_uuid) {
                    secondUuids.push(foods[id].brand_uuid);
                }
            });

            fetchDocumentsById(secondUuids).then((documents) => {
                let subrecipes = indexBy(
                    documents.filter((d) => d && d.type == 'recipe'),
                    'uuid'
                );
                let subdetails = indexBy(
                    documents.filter((d) => d && d.type == 'recipe_details'),
                    'uuid'
                );
                let merchants = indexBy(
                    documents.filter((d) => d && d.type == 'merchant'),
                    'uuid'
                );
                let brands = indexBy(
                    documents.filter((d) => d && d.type == 'brand'),
                    'uuid'
                );

                recipes = { ...recipes, ...subrecipes };
                details = { ...details, ...subdetails };

                // Flatten all ingredients from all detail records, we want to include
                // subrecipes ingredients in this ingredients list as well.
                let ingredients = flattenIngredients(details);

                // Load foods too, rendering needs that as well.
                let ingredientIds = getFoodIdsFromIngredients(ingredients);

                fetchDocumentsById(ingredientIds).then((documents) => {
                    foods = {
                        ...foods,
                        ...indexBy(
                            documents.filter((d) => d && d.type === 'food'),
                            'uuid'
                        ),
                    };
                    subrecipes = indexBy(
                        documents.filter((d) => d && d.type == 'recipe'),
                        'uuid'
                    );
                    subdetails = indexBy(
                        documents.filter((d) => d && d.type == 'recipe_details'),
                        'uuid'
                    );

                    return resolve({
                        recipes,
                        details,
                        foods,
                        merchants,
                        subrecipes,
                        subdetails,
                        brands,
                        locations,
                        content: {
                            ...recipes,
                            ...details,
                            ...foods,
                            ...merchants,
                            ...subrecipes,
                            ...subdetails,
                            ...brands,
                            ...locations,
                        },
                    });
                });
            });
        });
    });
}

export function getRecipesForMeals(meals) {
    const uuids = [];

    // Deduplicate and map in one step
    meals.forEach((meal) => {
        if (uuids.indexOf(meal.recipe_uuid) == -1) {
            uuids.push(meal.recipe_uuid);
        }
    });

    return fetchDocumentsById(uuids);
}

export function getParticipantsForProfileByMealType(profile, mealType, alwaysIncludePrimary = true) {
    const participants = [];

    const { preferences = {}, family = [] } = profile;
    const { breakfasts, lunches, dinners, snacks } = preferences;

    if (
        (mealType === 'Breakfast' && breakfasts) ||
        (mealType === 'Lunch' && lunches) ||
        (mealType === 'Dinner' && dinners) ||
        (mealType === 'Snack' && snacks)
    ) {
        const name = profile.name ? profile.name : profile.first_name;

        participants.push({
            uuid: profile.uuid,
            name,
            portion: profile.portion || 1,
        });
    }

    const propMap = {
        Breakfast: 'breakfasts',
        Lunch: 'lunches',
        Dinner: 'dinners',
        Snack: 'snacks',
    };

    family.forEach((member) => {
        if (member[propMap[mealType]]) {
            participants.push(member);
        }
    });

    if (alwaysIncludePrimary && !isParticipating(participants, profile)) {
        participants.unshift({
            uuid: profile.uuid,
            name: profile.first_name || profile.name || profile.email,
            portion: profile.portion,
        });
    }

    return participants;
}

export function getParticipantsForMeal(meal, profile) {
    if (!profile) {
        return null;
    }

    if (!meal.participants) {
        const name = profile.name ? profile.name : profile.first_name;
        //Use portion of 1 because when a meal doesn't have participants it is a food that should not be pre-scaled
        return [{ uuid: profile.uuid, name, portion: 1 }];
    }

    let guestNo = 1;
    return (meal.participants || '').split(',').map((uuid) => {
        if (uuid === profile.uuid) {
            const name = profile.name ? profile.name : profile.first_name;
            return { uuid, name, portion: profile.portion };
        }

        // Find the uuid in the family array, use that
        let member = profile.family.filter((m) => m.uuid === uuid)[0];
        if (member) {
            return member;
        }

        return { uuid, name: 'Guest #' + guestNo++, portion: 1 };
    });
}

/**
 * Calculates the default participants for the current profile on the current recipe. This tries
 * to figure out if its a breakfast lunch and dinner and appropriately add those family members to the meal
 * @param  {[type]} recipe  [description]
 * @param  {[type]} profile [description]
 * @return {[type]}         [description]
 */
export function getDefaultParticipantsForRecipe(content, profile) {
    // if the profile is not set, we don't have any participants still
    if (!profile) {
        return null;
    }

    const name = profile.first_name || profile.name;

    let participants = [];

    const isBreakfast = content.tags.includes('Breakfast') || content.tags.includes('Breakfast Side Dish'),
        isLunch = content.tags.includes('Lunch') || content.tags.includes('Lunch Side Dish'),
        isDinner = content.tags.includes('Main Dish') || content.tags.includes('Side Dish');

    // Does the main profile usually avoid this meal type?
    if (isDinner && !profile.preferences.dinners) {
    } else if (isLunch && !profile.preferences.lunches) {
    } else if (isBreakfast && !profile.preferences.breakfasts) {
    } else {
        participants.push({ uuid: profile.uuid, name, portion: profile.portion });
    }

    const family = profile.family.filter((member) => {
        // First, what kind of a meal is this?
        if (isDinner && !member.dinners) {
        } else if (isLunch && !member.lunches) {
        } else if (isBreakfast && !member.breakfasts) {
        } else {
            return true;
        }

        return false;
    });

    family.map(({ uuid, name, portion }) => ({ uuid, name, portion })).forEach((member) => participants.push(member));

    // Are there no participants at all? Add the main profile as the only participant.
    if (!participants.length) {
        participants.push({ uuid: profile.uuid, name, portion: profile.portion });
    }

    return participants;
}

export function isParticipating(participants, participant, mealType = null) {
    // Guard against participant being null, and the meal type being invalid
    if (!(participant && participant.uuid)) {
        return false;
    }

    if (mealType && !(participants && participants[mealType])) {
        return false;
    }

    if (!mealType && !participants) {
        return false;
    }

    const result = (mealType ? participants[mealType] : participants).filter((p) => p.uuid === participant.uuid);

    return result.length > 0;
}

export function getPrimaryMeal(meals, recipes = {}, foods = {}, debug = false) {
    if (!(meals && meals.length > 0)) {
        return { titles: [], photos: [], others: [], dishes: 0, beverages: 0 };
    }

    const edibleMeals = meals.filter((m) => ['fresh', 'leftover', 'food'].includes(m?.meal_type));

    if (edibleMeals.length > 1) {
        edibleMeals.sort(mealSortCompare);
    }

    const primary = edibleMeals[0];

    let dishes = 0,
        beverages = 0;

    const deletedMeals = edibleMeals.filter((m) => m.deleted);

    const others = edibleMeals
        .map((m) => {
            if (m.deleted) {
                return;
            }

            let content = null;

            if (m.recipe_uuid && recipes && recipes[m.recipe_uuid]) {
                content = recipes[m.recipe_uuid];
            }

            if (m.food_uuid && foods && foods[m.food_uuid]) {
                content = foods[m.food_uuid];
            }

            if (content && (content.tags || []).includes('Beverage')) {
                beverages++;
            } else {
                dishes++;
            }

            return content;
        })
        .filter((v) => v);

    const recipe = primary && primary.recipe_uuid && recipes ? recipes[primary.recipe_uuid] : null,
        food = primary && primary.food_uuid && foods ? foods[primary.food_uuid] : null;

    return {
        primary,
        recipe,
        food,
        dishes,
        beverages,
        content: recipe || food,
        others,
        titles: others.map((o) => {
            if (o.type === 'food') {
                return o.pretty_name || o.name;
            }

            return o.title;
        }),
        deletedTitles: edibleMeals
            .filter((m) => m.deleted)
            .map((m) => {
                let c = recipes[m?.recipe_uuid] || foods[m?.food_uuid];

                return c?.title || c?.pretty_name || c?.name;
            }),
        photos: aggregateMealPhotos(edibleMeals, recipes, foods),
    };
}

export function aggregateMealPhotos(meals, recipes, foods, daily_log = false) {
    const photos = [];

    const self_host = getConfig('self_host');

    meals.sort(daily_log ? mealSortByCreated : mealSortCompare).forEach((meal) => {
        if (meal.deleted) {
            return;
        }

        const type = meal.meal_type || meal.type; // allow for scheduled or meal plan meal items.

        if (
            (type === 'fresh' || type === 'leftover') &&
            recipes &&
            recipes[meal.recipe_uuid] &&
            recipes[meal.recipe_uuid].image
        ) {
            photos.push({ url: recipes[meal.recipe_uuid].image, logged_portion: meal.logged_portion });
        }

        if (
            (type === 'fresh' || type === 'leftover') &&
            foods &&
            foods[meal.food_uuid] &&
            foods[meal.food_uuid].image
        ) {
            photos.push({ url: foods[meal.food_uuid].image, logged_portion: meal.logged_portion });
        }

        if (type === 'food' && foods && foods[meal.food_uuid] && foods[meal.food_uuid].image) {
            photos.push({ url: foods[meal.food_uuid].image, logged_portion: meal.logged_portion });
        }

        if (meal.image_uuids && meal.image_uuids.length) {
            meal.image_uuids.forEach((uuid) => {
                photos.push({
                    url: 'https://' + self_host + '/file-proxy/' + uuid + '?type=image',
                    isDeletable: true,
                    logged_portion: meal.logged_portion,
                });
            });
        }
    });

    // ensure that we get the best image
    photos.sort((a, b) => {
        if (a.isDeletable && !b.isDeletable) return -1;
        if (!a.isDeletable && b.isDeletable) return 1;

        if (a.isDeletable && b.isDeletable) {
            return photos.indexOf(b) - photos.indexOf(a);
        }

        return 0;
    });

    return photos;
}

export function getCurrentActiveMealType() {
    const explicit = store.get('meal-feed-explicit-meal');

    if (explicit) {
        return explicit;
    }

    const now = moment();

    // Your moment at midnight
    const midnight = moment().startOf('day');

    // Difference in minutes
    const sinceMidnight = now.diff(midnight, 'minutes');

    if (sinceMidnight < 10 * 60) {
        return 'Breakfast';
    }

    if (sinceMidnight < 11.5 * 60) {
        return 'Snack';
    }

    if (sinceMidnight < 16 * 60) {
        return 'Lunch';
    }

    return 'Dinner';
}

export function getMealSlotsFromMeals(meals) {
    let collector = {};

    meals.forEach((meal) => {
        const key = meal.date + meal.meal;

        collector[key] = collector[key] || { date: meal.date, mealType: meal.meal };
    });

    return Object.keys(collector).map((key) => collector[key]);
}

export function getContentForMeal(meal, contents) {
    let content = null;

    if (meal.deleted) {
        return;
    }

    if (meal.recipe_uuid) content = contents[meal.recipe_uuid];
    if (meal.food_uuid) content = contents[meal.food_uuid];

    return content;
}

export function getTagsForMeals(meals, contents) {
    let tags = [];

    meals.forEach((meal) => {
        let content = getContentForMeal(meal, contents);

        if (!content) {
            return;
        }

        tags = tags.concat((content && content.tags) || []);
    });

    tags = tags.filter((tag, i) => tags.indexOf(tag) === i);

    return tags;
}

export function getIngredientTagsForMeals(meals, contents) {
    let tags = [];

    meals.forEach((meal) => {
        let content = getContentForMeal(meal, contents);

        if (!content) {
            return;
        }

        tags = tags.concat((content && content.ingredient_tags) || []);
    });

    tags = tags.filter((tag, i) => tags.indexOf(tag) === i);

    return tags;
}

export const isDateAvailable = (date, food) => {
    const availableOn = getDateAvailables(food).map((d) => d.format('YYYY-MM-DD'));

    date = moment(date);

    const needDeliveryOn = [
        date.format('YYYY-MM-DD'),
        date.subtract(1, 'day').format('YYYY-MM-DD'),
        date.subtract(1, 'day').format('YYYY-MM-DD'),
        date.subtract(1, 'day').format('YYYY-MM-DD'),
        date.subtract(1, 'day').format('YYYY-MM-DD'),
    ];

    // If there is an intersection between availableOn and needDeliveryOn,
    // then we can successfully deliver this food on that date.
    return needDeliveryOn.filter((d) => availableOn.includes(d)).length > 0;
};

export const getDateAvailables = (food) => {
    if (!food) {
        return false;
    }

    if (Array.isArray(food)) {
        if (food.length > 1) {
            return false;
        }
        food = food[0];
    }

    if (!food.mapped_from) {
        return false;
    }

    return food.mapped_from
        .flat(1)
        .map((p) => p['dates_available'])
        .flat(1)
        .flat(1)
        .map((p) => moment(p));
};

export const getMealInfo = (primary, recipes, foods) => {
    let mealName = null;
    let productType = null;
    let brandName = null;

    if (primary) {
        if ((primary.meal_type === 'fresh' || primary.meal_type === 'leftover') && recipes[primary.recipe_uuid]) {
            const recipe = recipes[primary.recipe_uuid];
            mealName = primary.meal_type === 'leftover' ? 'Leftover - ' + recipe.title : recipe.title;
            productType = 'recipe';
        } else if (primary.meal_type === 'food' && foods[primary.food_uuid]) {
            const food = foods[primary.food_uuid];
            mealName = food.name;
            brandName = food.brand_name;
            productType = food.product_type;
        } else {
            mealName = '';
            productType = primary.meal_type;
        }
    }

    return {
        mealName: mealName,
        productType: productType,
        brandName: brandName,
    };
};

const mealTypeServingMap = {
    Breakfast: 'breakfast',
    Lunch: 'lunch',
    Snack: 'snack',
    'Main Dish': 'dinner',
    Dinner: 'dinner',
};

export const getMaxCostPerServingForMealType = (mealType, preferences) => {
    const serving = mealTypeServingMap[mealType];
    const maxCostPerServingForMealType = preferences[`${serving}_max_cost_per_serving`];
    return maxCostPerServingForMealType ? { lte: maxCostPerServingForMealType } : undefined;
};

export function getMealsByGrocery(grocery, allMeals = []) {
    if (!grocery || !allMeals || !Array.isArray(allMeals)) {
        return [];
    }

    return allMeals?.filter((meal) => {
        return grocery.meal_uuids?.split(',').includes(meal.uuid);
    });
}

export function getPortionByParticipants(participants) {
    return participants.reduce((total, member) => {
        const portion = Number(member.portion);
        if (member.portion && !isNaN(portion)) {
            return total + portion;
        }
        return total + 1;
    }, 0);
}

export function getNeededPerMeal(meals, user) {
    return meals.reduce((acc, meal) => {
        const participants = getParticipantsForMeal(meal, user);
        const neededPerMeal = getPortionByParticipants(participants);
        return acc + neededPerMeal;
    }, 0);
}

export function calculateQuantityByNewMeals(newMeals, grocery, foods, recipes, currentMeals = [], user) {
    let mealGroceries = [];

    if (grocery) {
        mealGroceries = getMealsByGrocery(grocery, currentMeals);
    }

    const allMeals = mealGroceries.concat(newMeals);

    return calculateQuantity(grocery, allMeals, foods, recipes, user);
}

export function recalculateQuantityByGrocery(grocery, foods, recipes, currentMeals = [], user) {
    const mealGroceries = getMealsByGrocery(grocery, currentMeals);
    return calculateQuantity(grocery, mealGroceries, foods, recipes, user);
}

export function calculateQuantity(grocery, meals, foods, recipes, user) {
    if (!meals || !meals[0]) {
        return 0;
    }

    const primary = meals[0];
    const content = !!recipes[primary.recipe_uuid] ? recipes[primary.recipe_uuid] : foods[primary.food_uuid];
    const servings = content?.servings || 1;

    let totalNeeded = 0;
    meals.forEach((meal) => {
        const participants = getParticipantsForMeal(meal, user);
        const neededPerMeal = getPortionByParticipants(participants);
        const totalDays = Math.floor(meal.scaling / neededPerMeal) || 1;

        totalNeeded += neededPerMeal * (totalDays || 1);
    });

    return servings > 0 ? Math.ceil(totalNeeded / servings) : 0;
}

export function getGramsByPackage(packageName, food) {
    const unitGrams = food.units?.find((pkg) => pkg.description === packageName);
    const pkgGrams = food.packaging?.find((pkg) => pkg.description === packageName);

    if (!!unitGrams) {
        return unitGrams?.grams;
    }

    if (!!food.packaging && pkgGrams) {
        return pkgGrams?.grams;
    }

    return 1;
}

export function getMealsToDeleteByGroceryNeeded(grocery, foods, recipes, meals, user, details = []) {
    if (!grocery) {
        return [];
    }

    const mealGroceries = getMealsByGrocery(grocery, meals);

    if (!grocery.quantity) {
        return mealGroceries;
    }

    let currentAmount = grocery.quantity;
    const packageName = grocery.weight;

    const foodGrocery = foods[grocery?.food_uuid];

    if (foodGrocery?.serving_unit === 'g' && !['g', 'ml', 'fluid oz', 'ounces', 'grams'].includes(packageName)) {
        const gramsPerPackage = getGramsByPackage(packageName, foodGrocery);

        currentAmount *= gramsPerPackage;
    }

    const sortedMeals = mealGroceries.slice().sort((mealA, mealB) => moment(mealB.date).diff(moment(mealA.date)));

    let mealsToDelete = [];
    for (const meal of sortedMeals) {
        const remainingMeals = sortedMeals.filter((m) => !mealsToDelete.map((d) => d.uuid).includes(m.uuid));

        let neededAmount = 0;
        if (foodGrocery?.serving_unit === 'g') {
            const groceriesForMeals = getGroceriesForMeals(remainingMeals, { recipes, details, foods });
            const recalculatedGrocery = groceriesForMeals?.find((p) => p.food_uuid == grocery?.food_uuid);

            if (recalculatedGrocery?.grams_needed) {
                neededAmount = Math.round(recalculatedGrocery.grams_needed);
            }
        } else {
            neededAmount = recalculateQuantityByGrocery(grocery, foods, recipes, remainingMeals, user);
        }

        if (neededAmount <= 0 || currentAmount >= neededAmount) {
            break;
        }

        if (!mealsToDelete.some((m) => m.uuid === meal.uuid)) {
            mealsToDelete.push(meal);
        }
    }

    return mealsToDelete;
}

export const getRepeatOverlaps = (
    mealsToRepeat,
    selection,
    uuidsToRepeat,
    ogLeftoverDates,
    allMeals,
    recipes,
    foods,
    user
) => {
    const overlaps = [],
        clears = [];

    mealsToRepeat = mealsToRepeat?.filter((p) => !p.deleted) || [];

    selection.forEach(({ date, mealType }) => {
        const sourceMeals = mealsToRepeat.map((m) => ({
            ...m,
            uuid: uuid.v4(), // Give every meal a new uuid
            logged_portion: null,
        }));

        const isDestMeal = (m) =>
            !m.deleted && m.meal === mealType && date.isSame(m.date, 'day') && !uuidsToRepeat.includes(m.parent_uuid);

        const destMeals = allMeals.filter(isDestMeal);

        if (destMeals.length) {
            overlaps.push({
                mealType,
                date,
                destMeals,
                sourceMeals,
            });
        } else {
            clears.push({
                mealType,
                date,
                sourceMeals,
            });
        }

        const { primary } = getPrimaryMeal(sourceMeals, recipes, foods);

        if (primary.meal_type === 'fresh') {
            const participants = getParticipantsForMeal(primary, user);
            const neededPerMeal = participants.reduce((total, member) => total + (member.portion || 1), 0);

            // How many days of leftovers did mealsToRepeat have?
            ogLeftoverDates.forEach((dummy, i) => {
                const leftoverDate = moment(date).add(i + 1, 'day');

                const isDestMeal = (m) =>
                    !m.deleted &&
                    m.meal === mealType &&
                    leftoverDate.isSame(m.date, 'day') &&
                    !uuidsToRepeat.includes(m.parent_uuid);
                const destMeals = allMeals.filter(isDestMeal);

                // Create a leftover record for each sourceMeal
                const sourceLeftovers = sourceMeals.map((sourceMeal) => ({
                    ...sourceMeal,
                    uuid: uuid.v4(),
                    meal_type: 'leftover',
                    parent_uuid: sourceMeal.uuid,
                    leftover_servings: neededPerMeal,
                }));

                if (destMeals.length) {
                    overlaps.push({
                        mealType,
                        date: leftoverDate,
                        sourceMeals: sourceLeftovers,
                        destMeals,
                    });
                } else {
                    clears.push({
                        mealType,
                        date: leftoverDate,
                        sourceMeals: sourceLeftovers,
                    });
                }
            });
        }
    });

    return { overlaps, clears };
};

export function getServingsNeededWithLoggedServings(profile, participants, loggedServings) {
    return participants.reduce(
        (total, member) => total + (loggedServings && member.uuid == profile.uuid ? loggedServings : member.portion),
        0
    );
}

export function getLoggedServingsOfRecipe(meal, recipe, roundToNearestServing = true) {
    function roundUpToThreeDecimalPoints(num) {
        return Math.round(num * 1000) / 1000;
    }

    function customRound(quotient, remainder, threshold = 5) {
        if (remainder > threshold) {
            return Math.ceil(quotient);
        } else {
            return Math.floor(quotient);
        }
    }

    if (meal?.logged_unit == "serving") {
        return meal?.logged_amount;
    }

    if (recipe?.grams_per_serving && meal?.logged_grams) {
        const servingSize = roundUpToThreeDecimalPoints(recipe.grams_per_serving);
        const quotient = meal.logged_grams / servingSize;
        const remainder = meal.logged_grams % servingSize;
        return roundToNearestServing ? customRound(quotient, remainder) : quotient;
    }

    if (recipe?.milliliters_per_serving && meal?.logged_milliliters) {
        const servingSize = roundUpToThreeDecimalPoints(recipe.milliliters_per_serving);
        const quotient = meal.logged_milliliters / servingSize;
        const remainder = meal.logged_milliliters % servingSize;
        return roundToNearestServing ? customRound(quotient, remainder) : quotient;
    }

    return meal?.logged_amount;
}

export function generateDates(date, leftovers) {
    const dates = [];

    let currentDate = moment(date);

    for (let i = 0; i < leftovers && dates.length < 5; i++) {
        currentDate = currentDate.add(1, 'days');
        dates.push(currentDate.clone());
    }

    return dates;
}

export function areParticipantsDifferent(newParticipants, oldParticipants) {
    const uuids1 = newParticipants.map((obj) => obj.uuid);
    const uuids2 = oldParticipants.map((obj) => obj.uuid);

    return !uuids1.every((uuid) => uuids2.includes(uuid)) || !uuids2.every((uuid) => uuids1.includes(uuid));
}

export function getMealAndLeftovers(meal, meals) {
    let parent = null;
    let allMeals = [];

    if (meal.meal_type == 'leftover') {
        parent = meals.find((item) => item.uuid === meal.parent_uuid);
    } else {
        parent = meal;
    }

    allMeals = meals.filter((item) => item.parent_uuid === parent?.uuid && !item.deleted);

    if (parent) {
        allMeals.unshift(parent);
    }

    return allMeals;
}

/**
 * Retrieves batch information for a given meal, including participants, servings needed, total days and leftover meals.
 *
 * @param {string} date - The date of the meal.
 * @param {string} mealType - The type of the meal.
 * @param {Object} profile - The user profile, used to get participants for the meal.
 * @param {Array} meals - An array of meal objects.
 *
 * @returns {Object|null} An object, or null if no meal is found.
 * @returns {Array} return.participants - An array of participants for the meal.
 * @returns {number} return.mealServingsNeeded - Total number of servings needed for the meal.
 * @returns {number} return.totalDays - The total number of days covered by the meal and leftovers.
 * @returns {Array} return.leftoverDates - Dates of leftover meals.
 */
export function getBatchInfo(date, mealType, profile, meals) {
    const alreadyScheduledMeal = meals.find(
        (meal) => meal.meal === mealType && meal.date === date && meal.meal_type !== 'leftover' && !meal.deleted
    );

    if (alreadyScheduledMeal) {
        const allMeals = getMealAndLeftovers(alreadyScheduledMeal, meals);
        const totalDays = allMeals.length;
        const participants = getParticipantsForMeal(alreadyScheduledMeal, profile);
        const mealServingsNeededPerDay = participants.reduce((sum, participant) => sum + participant.portion, 0);
        const mealServingsNeeded = mealServingsNeededPerDay * totalDays;
        const leftoverDates = meals
            .filter((meal) => meal.parent_uuid == alreadyScheduledMeal.uuid && !meal.deleted)
            .map((meal) => meal.date);

        return { participants, mealServingsNeeded, totalDays, leftoverDates };
    }

    return null;
}

export function recalculateScaling(dish, participants, recipe, totalDays, user, meals) {
    let allMeals = getMealAndLeftovers(dish, meals);
    const servings = recipe.servings || 1;
    let totalNeeded, parent;

    if (dish) {
        if (dish.meal_type == 'leftover') {
            parent = meals.find((item) => item.uuid === dish.parent_uuid);
        } else {
            parent = dish;
        }

        let baseAmount = 0;
        // Don't count logged amount from removed meals
        if (totalDays < allMeals.length) {
            allMeals = allMeals.slice(0, totalDays);
        }

        // Copy parent meals logged_amount to new meals
        if (totalDays > allMeals.length) {
            const loggedServings = getLoggedServingsOfRecipe(parent, recipe);
            baseAmount = (totalDays - allMeals.length) * loggedServings;
        }

        const otherUsersMealServingsNeededPerDay = participants.reduce(
            (sum, participant) => sum + (participant.uuid == user.uuid ? 0 : participant.portion),
            0
        );
        totalNeeded =
            otherUsersMealServingsNeededPerDay * totalDays +
            allMeals.reduce((sum, meal) => sum + getLoggedServingsOfRecipe(meal, recipe), baseAmount);
    } else {
        const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
        totalNeeded = neededPerMeal * totalDays;
    }

    let scaling = Math.ceil(totalNeeded / servings);

    return scaling;
}
