import { useEffect } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import PropTypes from 'prop-types';
import Analytics from '@utils/Analytics';
import store from 'store';
import { storeTokenInBackend, LOCALSTORAGE_KEY_DEVICE_TOKEN } from './PushNotificationPrompt.react';

const PushNotificationsHandler = (props, context) => {
    useEffect(() => {
        if (!Capacitor.isPluginAvailable('PushNotifications')) {
            return;
        }
        if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
            const storedToken = store.get(LOCALSTORAGE_KEY_DEVICE_TOKEN);
            const syncTokenIfChanged = async () => {
                const permissions = await PushNotifications.checkPermissions();
                if (permissions.receive !== 'granted') return;

                const handleTokenRefresh = async (token) => {
                    if (!token?.value) return;

                    if (token.value !== storedToken) {
                        console.log('Push token changed or rotated, syncing...');
                        store.set(LOCALSTORAGE_KEY_DEVICE_TOKEN, token.value);
                        await storeTokenInBackend(token.value);
                    }
                    PushNotifications.removeAllListeners('registration');
                };
                PushNotifications.addListener('registration', handleTokenRefresh);

                try {
                    await PushNotifications.register();
                } catch (err) {
                    console.warn('Silent push token refresh failed:', err);
                }
            };
            if (storedToken) {
                syncTokenIfChanged();
            }

            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                Analytics.trackPushNotificationDelivered({
                    'Notification ID': notification.id,
                    'Device Type': Capacitor.getPlatform(), // 'ios', 'android'
                });
            });

            PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
                PushNotifications.removeAllDeliveredNotifications();
                const notificationId = notification.notification.id;
                const url = notification.notification.data?.url;

                Analytics.trackPushNotificationOpened({
                    'Notification ID': notificationId,
                    'Device Type': Capacitor.getPlatform(), // 'ios', 'android'
                });

                if (url) {
                    Analytics.trackPushNotificationAction({
                        'Notification ID': notificationId,
                        'Action Type': 'Navigate', // 'Navigate'
                        'Action Value': url, // When type is Navigate it should be a URL
                    });
                    if (context.router) {
                        // Workaround: If the app is already on /meals, React Router won't detect a navigation change
                        // because it's technically the "same page" with different query params.
                        // To force React Router to recognize the navigation, we first navigate to /my-account
                        // (a different page) and then immediately navigate to the intended URL after a short delay.
                        if (url.startsWith('/meals')) {
                            context.router.push('/my-account');
                        }
                        context.router.push(url);
                    }
                }
            });

            return () => {
                PushNotifications.removeAllListeners();
            };
        }
    }, []);

    return null;
};

PushNotificationsHandler.contextTypes = {
    router: PropTypes.object,
};

export default PushNotificationsHandler;
