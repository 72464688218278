'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Browser from '../Search/Browser.react';
import ResultsMini from '../Search/ResultsMini.react';
import SearchKeywords from '../Search/Filters/SearchKeywords.react';
import FoodDetailsModal from '../Search/Modals/FoodDetailsModal.react';
import BarcodeScanner from '../Admin/Foods/Tools/BarcodeScanner.react';

import AuthStore from '../../stores/AuthStore';

import { getConfig } from '../../utils/Env';
import Analytics from '../../utils/Analytics';

import './SearchFoodsModal.scss';
import FoodEditorModal from './Modals/FoodEditorModal';

export default class SearchFoodsModal extends Component {

    static propTypes = {
        onSelectFood: PropTypes.func,
        onSelectFoodDetails: PropTypes.func,
        onSelectRecipe: PropTypes.func,
        onSelectBrand: PropTypes.func,
        modalTitle: PropTypes.string,
        allowedTypes: PropTypes.array,
        inhibitSearchOnMount: PropTypes.bool,

        defaultTerms: PropTypes.string,
        defaultTags: PropTypes.array,
        defaultFilters: PropTypes.object,
        defaultLanguage: PropTypes.string,
        showCreateCustom: PropTypes.func,

        useMiniResults: PropTypes.bool,

        searchKeywordsPlaceholder: PropTypes.string,
        createCustomButtonCopy: PropTypes.string,

        extraAnalyticsProps: PropTypes.object,
    };

    static defaultProps = {
        modalTitle: 'Add an Ingredient',
        selectButtonText: 'Add ingredient',
        allowedTypes: ['food'],
        inhibitSearchOnMount: true,
        defaultTerms: '',
        defaultTags: [],
        defaultFilters: {},
        defaultLanguage: 'en',
        showCreateCustom: true,
        searchKeywordsPlaceholder: "Search for foods",
        useMiniResults: true,
        createCustomButtonCopy: "Create New Ingredient",
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
        user: PropTypes.object
    };

    static childContextTypes = {
        onSelectFood: PropTypes.func,
        onSelectFoodDetails: PropTypes.func,
        onSelectRecipe: PropTypes.func,
        onSelectBrand: PropTypes.func,
        onSelectCollection: PropTypes.func,
        showFoodDetails: PropTypes.func,
        showRecipeDetails: PropTypes.func,
        showCollectionDetails: PropTypes.func,

        showCreateCustom: PropTypes.func,
        createCustomButtonCopy: PropTypes.node,
        showResultsFavoriteBtn: PropTypes.bool,
        hideProductTypeButtons: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        const { user } = this.context;

        this.state = {
            params: {
                language: props.defaultLanguage,
                terms: props.defaultTerms,
                types: props.allowedTypes.slice(),
                filters: {
                    tags: props.defaultTags.slice(),
                    ...props.defaultFilters,
                },
                include_library: true,
                size: 20,
                include_merchants: user?.features?.source_libraries || null,
                sort_by: 'ready_state',
            },

            dirty: !props.inhibitSearchOnMount,

            isFoodsModalOpen: false,
            modalTitle: 'Add an ingredient',
            isAdvancedFiltersOpen: false,
        };
    }

    getChildContext = () => {
        const {
            allowedTypes,
            onSelectFood,
            onSelectFoodDetails,
            onSelectRecipe,
            onSelectBrand,
            onSelectCollection,
            createCustomButtonCopy,
        } = this.props;

        let showCreateCustom = null;

        if (this.props.showCreateCustom) {
            showCreateCustom = this.props.showCreateCustom;
        } else if (allowedTypes.includes('food')) {
            showCreateCustom = this.showCreateCustom;
        }

        return {
            onSelectFood,
            onSelectFoodDetails,
            onSelectRecipe,
            onSelectBrand,
            onSelectCollection,
            showFoodDetails: this.showFoodDetails,
            showRecipeDetails: this.showRecipeDetails,
            showCollectionDetails: this.showCollectionDetails,

            showCreateCustom,
            createCustomButtonCopy,
            showResultsFavoriteBtn: false,
            hideProductTypeButtons: false,
        };
    }

    showCreateCustom = () => {
        Analytics.startUseOwnFood({'Context': 'Ingredient'});

        const { showCreateCustom } = this.props;

        if (typeof showCreateCustom === 'function') {
            showCreateCustom();
            return;
        }

        const { createCustomButtonCopy } = this.props;

        this.setState({isFoodsModalOpen: true, modalTitle: createCustomButtonCopy});
    }

    onShowAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: true});
    }

    onHideAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: false});
    }

    onExecuteSearch = (params) => {
        const { extraAnalyticsProps = {} } = this.props;

        Analytics.searchIngredients(params.terms, params.filters, params.sort_by, extraAnalyticsProps);
    }

    onChangeParams = (params) => {
        this.setState({params, dirty: true});
    }

    onSaveFood = (food) => {
        const { onSelectFood } = this.props;
        const { description, amount, grams } = food.default_unit || {};

        Analytics.savedCustomFood({'Context': 'Ingredient'}, food);

        onSelectFood(food, description, amount, grams, food.default_unit);
    }

    showFoodDetails = (food) => {
        this.setState({food});
    }

    showRecipeDetails = () => {
        // no-op, unfortunately we run into an inclusion loop if we try to include recipe details modal here.
    }

    closeDetailsModal = () => {
        this.setState({food: null});
    }

    renderDetailsModal = () => {
        const { food } = this.state;
        const { onSelectFood, selectButtonText } = this.props;

        if (food) {
            return <FoodDetailsModal food={food}
                        closeModal={this.closeDetailsModal}
                        onSelectFood={onSelectFood}
                        addInstructionsText="How much to add to the recipe?"
                        selectButtonText={selectButtonText} />
        }
    }

    resetSwitcherScroll = () => {
        if (!this.switcher) {
            return;
        }

        this.switcher.scrollLeft = 0
    }

    realizeModeSwitcher = (el) => {
        if (el) {
            el.scrollLeft = 0;
        }

        this.switcher = el;
    }

    closeFoodEditorModal = () => {
        this.setState({isFoodsModalOpen: false});
    }

    renderFoodEditorModal = () => {
        const { profile, isFoodsModalOpen, missingBarcode = '' } = this.state;
        const { closeModal, modalTitle } = this.props;

        if (!isFoodsModalOpen) {
            return;
        }

        return (
            <FoodEditorModal
                modalTitle={modalTitle}
                contentLabel='Edit Food Details'
                profile={profile}
                closeModal={this.closeFoodEditorModal}
                defaultBarcode={missingBarcode}
                onSaveFood={this.onSaveFood}
                retryBarcodeScan={this.refs.scanner.retryBarcodeScan}/>
        );
    }

    onBarcodeNotFound = (barcode, isManualEntry) => {
        this.setState({isFoodsModalOpen: true, missingBarcode: barcode});
    }

    onBarcodeFound = (food) => {
        const { onSelectFood } = this.props;
        onSelectFood(food);

        if (typeof this.refs.scanner?.closeModal === 'function') {
            this.refs.scanner.closeModal();
        }
    }

    render = () => {
        const { closeModal, modalTitle, allowedTypes, inhibitSearchOnMount,
                searchKeywordsPlaceholder, useMiniResults } = this.props;
        const { dirty, isAdvancedFiltersOpen, params } = this.state;
        const { isMobile } = this.context;

        const canBarcodeScan = allowedTypes.includes('food') && window.cordova && window.cordova.plugins && window.cordova.plugins.barcodeScanner;

        return (
            <>
                <Modal isOpen={true}
                    onRequestClose={closeModal}
                    closeModal={closeModal}
                    contentLabel="Search for an ingredient"
                    className="el-modal el-modal1"
                    overlayClassName="el-modal-overlay"
                    closeTimeoutMS={250}>

                    <div className="el-modal-container el-modal1-container search-foods-modal-container el-fonts">
                        <header data-has-controls={false}>
                            <button className="el-modal-back-btn" onClick={closeModal}>
                                <span>Back</span>
                            </button>

                            <h2>{modalTitle}</h2>
                        </header>

                        <div className="el-modal-body-container el-modal1-body-container el-fonts">
                            <header>
                                <div className="el-toolbar1 filters-toolbar">
                                    <SearchKeywords params={params}
                                        onChangeParams={this.onChangeParams}
                                        className="el-toolbar-search"
                                        placeholder={searchKeywordsPlaceholder}
                                        defaultWithTermsSortBy="ready_state" />

                                    <BarcodeScanner className="el-toolbar-btn scan-barcode-btn" ref="scanner"
                                        onBarcodeNotFound={this.onBarcodeNotFound}
                                        onBarcodeFound={this.onBarcodeFound}
                                        barcodeContext={"Search Foods Modal"} />

                                    <button className="el-toolbar-btn" onClick={this.onShowAdvancedFilters}>
                                        <i className="feather feather-filter" />
                                    </button>
                                </div>
                            </header>

                            <Browser contextName="SearchFoodsModal"
                                params={params}
                                onChangeParams={this.onChangeParams}
                                allowedTypes={allowedTypes}
                                inhibitSearchOnMount={inhibitSearchOnMount}
                                showTypePicker={true}
                                resultsComponent={useMiniResults ? ResultsMini : null}
                                onShowAdvancedFilters={this.onShowAdvancedFilters}
                                onHideAdvancedFilters={this.onHideAdvancedFilters}
                                onExecuteSearch={this.onExecuteSearch}
                                isAdvancedFiltersOpen={isAdvancedFiltersOpen} />
                        </div>
                    </div>
                </Modal>

                {this.renderDetailsModal()}
                {this.renderFoodEditorModal()}
            </>
        );
    }

}
