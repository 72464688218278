import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './ChangePasswordModal.scss';
import SetPassword from '../../Widgets/header/SetPassword.react';
import Analytics from '../../../utils/Analytics';

export default class ChangePasswordModal extends Component {
    static contextTypes = {
        confirm: PropTypes.func,
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            showConfirm: false,
        };
    }

    closeModal = (triggerEvent = true) => {
        const { closeModal } = this.props;

        if (triggerEvent) {
            Analytics.closeSetPasswordModal();
        }

        closeModal && closeModal();
    }

    renderConfirmFooter = () => {
        return (
            <footer>
                 <button className="el-modal-ok-btn el-raspberry-btn confirm-password-btn" onClick={this.closeModal}>OK</button>
            </footer>
        );
    }

    render = () => {
        const { showConfirm } = this.state;
        const { isPro } = this.context;
        return (
            <Modal isOpen={true}
                closeModal={this.closeModal}
                className="el-modal el-modal3 el-modal3-centered"
                overlayClassName="el-modal-overlay"
                contentLabel="Change password">
                <div className="el-modal-container el-modal3-container change-password-modal el-fonts">
                    <header>
                        <span className="logo-container">
                            <img className="header-logo" src="https://static.chewba.info/images/eatlove-logo-navy-blue-green-2017.svg" />
                            {isPro ? <span>PRO</span> : null}
                        </span>
                        {showConfirm ? <p className="p4">Your password has been updated.</p> : <h2>Choose your new password</h2> }
                         <span className="el-modal-close-x">
                            <i className="feather feather-x" onClick={this.closeModal}/>
                        </span>
                        {showConfirm ?
                            <div className="change-password-check">
                                <i className="feather feather-check el-small-circle-icon"></i>
                            </div>
                        : null}
                    </header>

                    {!showConfirm ? <SetPassword title="" onClose={() => this.closeModal(false)} /> : this.renderConfirmFooter() }
                </div>
            </Modal>
        );
    }
}
