'use strict'

import { render } from 'react-dom'
import React from 'react'
import { Link } from 'react-router';

import './Hero.scss';

export default function Hero(props) {
    return (
        <div className="homepage-hero">
            <div className="hero-text-container">
                <div className="hero-heading-container">
                    <h1>Healthy Eating Habits Made Incredibly Simple.</h1>
                    <p className="desc">EatLove provides personalized nutrition plans, meal recommendations, and coaching to help you build lasting, healthy habits. <em>Easy recipes</em>, <em>smart restaurant choices</em>, <em>grocery lists</em> with <em>optional grocery and meal delivery</em>.</p>
                </div>

                <p className="above-cta-text">Get started for <em>FREE</em> today!</p>

                <div className="cta-container">
                    <Link to="/new-account" className="start-trial-btn">For Personal Use</Link>
                    <Link to="/pro" className="pro-instead-btn">for business use<br /><em>(professionals)</em></Link>
                </div>

                <div className="secondary-container">
                    <p className="subtext">Referred by your gym, fitness or nutrition professional?</p>
                    <button className="redeem-invite-btn" onClick={props.showRetrieveInvite}>redeem invite</button>
                </div>
            </div>

            <img className="app-screenshot" src="https://static.chewba.info/images/f722c95e-1fc6-4254-aac2-19c731638261.png" />
        </div>
    );
}

