'use strict';

import assign from 'object-assign';
import uuid from 'uuid';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { client, xml } from '@xmpp/client/dist/xmpp';
import { Plugins } from '@capacitor/core';
import * as firebase from "firebase/app";
import "firebase/messaging";
import store from "store";

import ChatConstants from '../constants/ChatConstants';
import UserConstants from '../constants/UserConstants';
import AuthStore from './AuthStore';
import Analytics from '../utils/Analytics';

import { getConfig } from '../utils/Env';

const CHANGE_EVENT = 'change';
const MESSAGE_EVENT = 'message'; // emitted when a new message arrives.
/*

Roster Item definition

rosterItem = {
    jid: 'my-contacts-jid@local',
    nick: 'Slarti Bartfest',
    image: 'https://static...',
    fake: false|true, // if the roster item leads to a real user or is a faked version
}

Chat Item definition (these are indexed under the roster item, so we keep everything straight)

chatItem = {
    // If this chat item represents a chat with a single personn, these two properties will be populated.
    // This is the only way for now, but we'll have to add more options once I add support
    // for multi-user chat and chat-threads on daily log pages (which I believe will be a
    // multi-user chat room as well)
    type: 'chat',
    with: 'who-you-chatting-withs-jid@local',

    earliest: 1203918231, // unix timestamp of the earliest message we've queried in this chat thread

    // Sequentially ordered array of messages sent to this chat, by all parties.
    messages: [],

    // Chat markers tell us if our messages have been delivered or read. We make this a bit easier on ourselves by
    // exposing chat markers as timestamps instead of an array of messages notifying the person on the other end of
    // changes.
    markers: {
        // Chat marker designating the last moment of the thread that we have read up to. Everything after this
        // moment is considered "unread"
        read: 43892108432, // unix timestamp

        // Chat markers designating the last moment the recipient was delivered a message.
        delivered: 4328904832, // unix timestamp

        // Chat marker designating the last moment the we have read receipt of for this chat thread (this is the last
        // message of ours that the recipient has seen)
        receipt: 1238091823, // unix timestamp
    },

    totals: {
        unread: 3, // Count of any messages are after markers.unread
        unreceipt: 3, // Count of messages that are after markers.read
        undelivered: 3, // Count of messages that are after markers.delivered
    }

    flags: {
        fake: false, // set to true if this is not a "real" chat thread that jabber understands
    },

    preview: 'wtf man you ghosting me or someshit?!',
}

messageItem = {
    id: '432819', uniquely generated id for this message. Used for chat markers, carbons, and corrections
    from: 'from-jid@local',
    to: 'to-jid@local',

    // unix timestamp for the message. It looks like if we're hydrating from the archive, then this will be populated
    // by the delay[stamp] property of the forward. If we receive the message directly, then we just take the current
    // timestamp.
    timestamp: 12038109823,

    // Message text
    messageText: 'This is the text of my message',

    // Can I put other shit here? Threading? File uploads? Pictures? Markdown? So many options!
    attachments: [],
}

attachment = {
    uuid: '',
    filename: '',
    mime_type: '',
}

*/
const _store = {
    whoami: null,
    status: 'disconnected',
    chats: [],
    roster: [],
    client: null,
    latest: null,

    notify_host: 'notify.eatlove.is',

    inhibitConnect: false,
    retry: 0,
    retrying: false,

    features: {
        push_notifications: false,
        pubsub: false,
    },

    // Client for firebase messaging. Initialize only once per app instance.
    firebase: {
        messaging: null,
    },
};

let messaging = null;

const ChatStore = assign({}, EventEmitter.prototype, {
    whoami: () => _store.whoami,
    getStatus: () => _store.status,
    getRoster: () => _store.roster,
    getChatWithJid: (jid, type = 'chat') => {
        return findOrCreateChatByWithAndType(jid, type);
    },

    chatSortRecency: (a, b) => {
        const aChat = ChatStore.getChatWithJid(a.rosterItem.jid),
          bChat = ChatStore.getChatWithJid(b.rosterItem.jid);

        const aLatestMessageTime = aChat.messages.length ? aChat.messages[aChat.messages.length - 1].timestamp.valueOf() : 0;
        const bLatestMessageTime = bChat.messages.length ? bChat.messages[bChat.messages.length - 1].timestamp.valueOf() : 0;

        if (aLatestMessageTime > bLatestMessageTime) return -1;
        if (aLatestMessageTime < bLatestMessageTime) return 1;
        return 0;
    },

    // Gets a list of all rosteritems with unread messages.
    getUnreads: () => {
        const noPrepTasksText = "You have no prep-ahead tasks today. Check back with me daily to chop ahead and save time. Enjoy your day!";

        const unreads = (_store.roster || []).map(rosterItem => {
            let chat = _store.chats.find(c => c.type === 'chat' && c.with === rosterItem.jid);

            if (chat && chat.messages && chat.messages.length && chat.messages[0].messageText == noPrepTasksText) {
                return null;
            }

            if (!(chat && chat.totals.unread > 0)) {
                return null;
            }

            return {rosterItem, unread: chat.totals.unread, preview: chat.preview};
        }).filter(v => v).sort(ChatStore.chatSortRecency);

        return unreads;
    },

    getLatest: () => {
        return _store.latest;
    },

    getLatestUnread: (includesFake = false) => {
        const chatsWithUnread = _store.chats.filter(chat => chat.totals.unread > 0 && (includesFake || !chat.flags.fake));

        if (chatsWithUnread.length === 0) {
            return null;
        }

        const latestUnreadMessageThread = chatsWithUnread.reduce((prev, current) => {
            return (prev.messages[prev.messages.length -1 ].timestamp > current.messages[current.messages.length -1 ].timestamp) ? prev : current
        })

        return latestUnreadMessageThread;
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },

    emitMessage: function(message) {
        this.emit(MESSAGE_EVENT);
    },

    addMessageListener: function(callback) {
        this.on(MESSAGE_EVENT, callback);
    },

    removeMessageListener: function(callback) {
        this.removeListener(MESSAGE_EVENT, callback);
    },

    disconnect: function() {
        return disconnectFromChat().then(() => {
            ChatStore.emitChange();
        });
    }
});

export default ChatStore;

// Stupid helper function to let us ignore resources when we don't need to pay attention to them..
function getJid(input) {
    // Is there a slash in here? We're only concerned with the username and the domain, not the path.
    if (input.indexOf('/')) {
        return input.split('/')[0];
    }

    return input;
}

function refreshRoster() {
    return AuthStore.fetch(getConfig('users_api') + '/roster').then(
        async response => {
            _store.roster = response.roster || [];
            debounceEmitChange();
        }
    );
}

function findOrCreateChatByWithAndType(withJid, type) {
    // If this message is FROM me, and it's a chat, then find who I sent it to and add it to their chat entry.
    // If this message is TO me, and it's a chat, then find who sent it and add it to their chat entry.
    let chat = _store.chats.find(c => c.type === 'chat' && c.with === withJid);

    // If the chat does not already exist, we should create it.
    // This would trigger the "first message" notification or something if we were gonna do that sort of thing.
    if (!chat) {
        chat = {
            type,
            with: withJid,
            messages: [],
            markers: {
                read: null,
                delivered: null,
                receipt: null,
            },
            flags: {},
            totals: {
                unread: 0,
                unreciept: 0,
                undelivered: 0,
            },
        };

        _store.chats.push(chat);
    }

    return chat;
}

/**
 * This function has a potential for getting sticky. Try to keep it as neat and elegant as possible as a rule.
 * @param  Element message  The xmpp stanza describing the message
 * @return Objectt          The chat item that the message should be placed into.
 */
function routeMessageToChat(message) {
    let chat = null;
    const fromJid = getJid(message.attrs.from);
    const withJid = fromJid === _store.whoami ? getJid(message.attrs.to) : fromJid;

    if (message.attrs.type === 'chat') {
        chat = findOrCreateChatByWithAndType(withJid, 'chat');
    } else if ((message.getChild('displayed') || message.getChild('received') || message.getChild('delivered')) && !message.getChild('thread')) {
        chat = findOrCreateChatByWithAndType(withJid, 'chat');
    } else if ((message.getChild('displayed') || message.getChild('received') || message.getChild('delivered')) && message.getChild('thread')) {
        // @todo - this space reserved for chat markers on threaded chats. Maybe, we'll see :)
    }

    return chat;
}

// Prevents multiple stanzas coming in from thrash-updating the subscribers.
const debounceEmitChange = debounce(() => ChatStore.emitChange(), 25);

/**
 * Synchronizes Marker Totals for unread and unreceipt messages.
 *
 * @param  {object} chat The chat object to synchronize totals for
 */
function syncMarkerTotals(chat) {
    // Recompute totals and preview, then fire the debounceEmitChange
    const unread = chat.markers.read
                 ? chat.messages.filter(m => m.from !== _store.whoami && m.timestamp.isAfter(chat.markers.read))
                 : chat.messages.filter(m => m.from !== _store.whoami);
    const unreceipt = chat.markers.receipt
                    ? chat.messages.filter(m => m.from !== _store.whoami && m.timestamp.isAfter(chat.markers.receipt))
                    : chat.messages.filter(m => m.from !== _store.whoami);
    chat.totals.unread = unread.length;
    chat.totals.unreciept = unreceipt.length;

    // If we have any unread messages, we use the first unread message as the preview
    if (unread.length) {
        chat.preview = unread[0].messageText;
        chat.previewTime = unread[0].timestamp;
    } else if (chat.messages.length) {
        // Otherwise we use the most recent message as the preview
        chat.preview = chat.messages[chat.messages.length - 1].messageText;
        chat.previewTime = chat.messages[chat.messages.length - 1].timestamp;
    }
}

function onReceiveMessageWithBody(stanza, timestamp = null, fromArchive = false) {
    // // Where does this chat message get routed to?
    let chat = routeMessageToChat(stanza);

    timestamp = timestamp || moment();

    if (!chat) {
        // What's going on here?
        return;
    }

    // Does this id already exist in the list?
    let message = stanza.attrs.id ? chat.messages.find(m => m.id == stanza.attrs.id) : null;

    if (!message) {
        // Append this chat to the chat body
        chat.messages.push(message = {id: stanza.attrs.id});
    }


    message.to = getJid(stanza.attrs.to);
    message.from = getJid(stanza.attrs.from);
    message.messageText = stanza.getChildText('body');
    message.timestamp = timestamp;
    message.attachments = stanza.getChildren('attachment').map(attachment => ({
        uuid: attachment.attrs.uuid,
        filename: attachment.attrs.filename,
        mime_type: attachment.attrs.mime_type,
        image_width: attachment.attrs.image_width,
        image_height: attachment.attrs.image_height,
    }));
    chat.messages.sort((a, b) => a.timestamp - b.timestamp);

    syncMarkerTotals(chat);
    debounceEmitChange();

    if (!fromArchive) {
        _store.latest = message;
        ChatStore.emitMessage();
    }
}

function onRecieveQueryFinish(message) {
    const { queryid, complete } = message.getChild('fin').attrs;

    if (!(queryid && complete)) {
        return;
    }

    // Find the chat with this queryid and clear it.
    const chat = _store.chats.find(c => c.queryid == queryid);

    if (chat) {
        delete chat.queryid;
    }
}

function onReceiveDisplayedMarker(message) {
    const chat = routeMessageToChat(message);
    const fromJid = getJid(message.attrs.from);

    // Find the original message this marker is referring to in the chat history
    let msgItem = chat.messages.find(m => m.id === message.getChild('displayed').attrs.id);

    // if we can't find it, ignore the marker
    if (!msgItem) {
        return;
    }

    // Is this marker from me or to me?
    if (fromJid == _store.whoami) {
        // If we're already more up-to-date than this message, don't do anything.
        if (chat.markers.read > msgItem.timestamp) {
            return;
        }

        chat.markers.read = msgItem.timestamp;
    } else {

        if (chat.markers.receipt > msgItem.timestamp) {
            return;
        }

        chat.markers.receipt = msgItem.timestamp;
    }

    syncMarkerTotals(chat);
    debounceEmitChange();
}

function onReceiveDeliveredMarker(message) {

    const chat = routeMessageToChat(message);
    const fromJid = getJid(message.attrs.from);

    // Find the original message this marker is referring to in the chat history
    let msgItem = chat.messages.find(m => m.id === message.getChild('delivered').attrs.id);

    // if we can't find it, ignore the marker
    if (!msgItem) {
        return;
    }

    // Is this marker from me or to me?
    if (fromJid !== _store.whoami) {
        // If we're already more up-to-date than this message, don't do anything.
        if (chat.markers.delivered > msgItem.timestamp) {
            return;
        }

        chat.markers.delivered = msgItem.timestamp;

        syncMarkerTotals(chat);
        debounceEmitChange();
    }


}

function markSendStatus(message, withJid, status) {
    const chat = ChatStore.getChatWithJid(withJid);
    const messageId = message.getChild(status)?.attrs?.id;
    const messageIndex = chat.messages.findIndex(message => message.id === messageId);
    chat.messages[messageIndex].send_status = status;    
}

function onReceiveMessage(message, timestamp = null, fromArchive = false) {
    // Does this message have a body?
    if (message.getChild('body') && message.getChildText('body')) {
        onReceiveMessageWithBody(message, timestamp, fromArchive);
    } else if (message.getChildren('attachment').length) {
        onReceiveMessageWithBody(message, timestamp, fromArchive);
    }

    const jidFrom = getJid(message.attrs.from);

    if (jidFrom !== _store.whoami && message.parent?.name !== "forwarded" && (message.getChild('body') || message.getChild('attachment'))) {
        const chat = ChatStore.getChatWithJid(jidFrom);
        markChatAsDelivered(jidFrom, chat.type)
    }


    // Does this message have a result with a forwarded message?
    if (message.getChild('result') &&
        message.getChild('result').getChild('forwarded') &&
        message.getChild('result').getChild('forwarded').getChild('message')) {

        timestamp = timestamp || moment(message.getChild('result').getChild('forwarded').getChild('delay').attrs.stamp);

        onReceiveMessage(message.getChild('result').getChild('forwarded').getChild('message'), timestamp, true);
    }

    // Carbon copy message sent
    if (message.getChild('sent') &&
        message.getChild('sent').getChild('forwarded') &&
        message.getChild('sent').getChild('forwarded').getChild('message')) {

        onReceiveMessage(message.getChild('sent').getChild('forwarded').getChild('message'), null, true);
    }

    if (message.getChild('fin')) {
        onRecieveQueryFinish(message);
    }

    if (message.getChild('displayed')) {
        onReceiveDisplayedMarker(message);
    }

    if (message.getChild('delivered')) {
        onReceiveDeliveredMarker(message);
    }


}


// Initialize Firebase
function initializeFirebase () {
    if (_store.firebase.messaging) {
        return _store.firebase.messaging;
    }

    const firebaseConfig = getConfig('firebase_config');
    const firebaseVapidKey = getConfig('firebase_public_vapid_key');

    if (!firebaseConfig) {
        return false;
    }

    try {
        firebase.initializeApp(firebaseConfig);

        const messaging = firebase.messaging();

        if (firebaseVapidKey) {
            messaging.usePublicVapidKey(firebaseVapidKey);
        }

        _store.firebase.messaging = messaging;

        // Setup firebase API callbacks
        messaging.onTokenRefresh(registerFirebaseDevice);
        messaging.onMessage(onFirebaseMessage);
    } catch (exp) {
        return false;
    }

    return _store.firebase.messaging;
}

function onFirebaseMessage(payload) {
    // not really expecting this function to be necessary since push notifications only get sent when the recipient has
    // no clients online.
}

async function enablePushNotificationsPubsubNode(nodeId) {
    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    // Ensure that the chat server is forwarding notifications to the notification server (fortunately idempotent)
    const id = uuid.v4().substring(0, 8);
    const response = await _store.client.iqCaller.request(
        xml('iq', {type: 'set', from: _store.whoami, id},
            xml('enable', {xmlns: 'urn:xmpp:push:0', jid: _store.notify_host, node: nodeId})
        ),
        30 * 1000
    );

    return response;
}

async function registerFirebaseDevice() {
    const messaging = initializeFirebase();

    if (!messaging) {
        return;
    }

    const deviceId = getConfig('device_id');
    const token = await messaging.getToken();

    if (!token) {
        // Uhhh, how did this happen? We shouldn't get here.
        await disableFirebaseDevice();

        return;
    }

    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    // If we're not registered, we need to request registration of this device for push notifications.
    let id = uuid.v4().substring(0, 8);
    let response = await _store.client.iqCaller.request(
        xml('iq', {type: 'set', to: _store.notify_host, from: _store.whoami, id},
            xml('query', {xmlns: 'jabber:iq:register'},
                xml('device_type', {}, 'firebase'),
                xml('device_id', {}, deviceId),
                xml('device_token', {}, token),
            )
        ),
        30 * 1000
    );

    const nodeId = response.getChild('query') && response.getChild('query').getChildText('node');

    enablePushNotificationsPubsubNode(nodeId);
}

async function disableFirebaseDevice() {
    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    // Tells arnold about our current device, but also tells arnold to
    // inhibit sending push notifications to it. We'll use XEP-0077 Unregister to do this.
    const deviceId = getConfig('device_id');

    // If we're not registered, we need to request registration of this device for push notifications.
    let id = uuid.v4().substring(0, 8);
    let response = await _store.client.iqCaller.request(
        xml('iq', {type: 'set', to: _store.notify_host, from: _store.whoami, id},
            xml('query', {xmlns: 'jabber:iq:register'},
                xml('remove'),
                xml('device_type', {}, 'firebase'),
                xml('device_id', {}, deviceId),
            )
        ),
        30 * 1000
    );

    const nodeId = response.getChild('query') && response.getChild('query').getChildText('node');

    enablePushNotificationsPubsubNode(nodeId);
}

async function enableFirebasePushNotifications() {
    // browse-based push notifications.
    if (Notification.permission === 'granted') {
        registerFirebaseDevice();
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
            if (permission === 'granted') {
                registerFirebaseDevice();
            } else {
                disableFirebaseDevice();
            }
        });
    } else {
        disableFirebaseDevice();
    }
}

async function enableGenericPushNotifications() {
    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    // Tells arnold about our current device, but does not register a token with it. This device can get it's type
    // updated later once it is supported.
    const deviceId = getConfig('device_id');

    // If we're not registered, we need to request registration of this device for push notifications.
    let id = uuid.v4().substring(0, 8);
    let response = await _store.client.iqCaller.request(
        xml('iq', {type: 'set', to: _store.notify_host, from: _store.whoami, id},
            xml('query', {xmlns: 'jabber:iq:register'},
                xml('device_type', {}, 'generic'),
                xml('device_id', {}, deviceId),
            )
        ),
        30 * 1000
    );

    const nodeId = response.getChild('query') && response.getChild('query').getChildText('node');

    enablePushNotificationsPubsubNode(nodeId);
}

function enablePushNotifications() {
    if (window.cordova) {
        // App-based push notifications.
        //
        // if (iOS) {
        // }
        //
        // if (Android) {
        // }
        enableGenericPushNotifications();
    } else if ('serviceWorker' in navigator &&
               'Notification' in window &&
               firebase && firebase.messaging && firebase.messaging.isSupported()) {
        enableFirebasePushNotifications();
    } else {
        enableGenericPushNotifications();
    }
}

function onRecieveIq(stanza) {
    const rosterEvents = stanza.children.filter(child => child?.attrs?.xmlns == "jabber:iq:roster");
    let rosterEventChildren, items;
    if (rosterEvents) {
        rosterEventChildren = rosterEvents.reduce((acc, event) => acc.concat(event.children), []);
        items = rosterEventChildren.find(child => child.name == "item");               
    }

    if (items) {
        refreshRoster();
    }
}

// Brokers received stanzas by message, iq, or presence
function onReceiveStanza(stanza) {
    if (!_store.client) { // we're not connected anymore, stop processing stanzas
        return;
    }
    try {
        // Roster may have been update on backend
        if (stanza.is('iq')) {
           onRecieveIq(stanza);
        }
    } catch (err) {
        // We catch all errors that can happen here otherwise the @xmpp library is a bitch about them.
        console.debug(err);
    }

    try {
        // Ok, so the idea of this function is that it will mutate the _store based on the stanza,
        // be it adding messages to the stack, updating chat markers, etc.
        if (stanza.is('message')) {            
            onReceiveMessage(stanza);
        }
    } catch (err) {
        // We catch all errors that can happen here otherwise the @xmpp library is a bitch about them.
        console.debug(err);
    }
}

function setPresence(type) {
    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    _store.client.send(xml('presence', {type}));
}

async function enableMessageCarbons() {
    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    await _store.client.iqCaller.request(
        xml(
            'iq',
            {
                type: 'set',
                xmlns: 'jabber:client',
                from: _store.whoami,
                id: uuid.v4().substring(0, 8),
            },
            xml('enable', {xmlns: 'urn:xmpp:carbons:2'})
        )
    );
}

async function enableStreamManagement() {
    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    await _store.client.iqCaller.request(
        xml(
            'iq',
            {
                type: 'set',
                xmlns: 'jabber:client',
                from: _store.whoami,
                id: uuid.v4().substring(0, 8),
            },
            xml('enable', {xmlns: 'urn:xmpp:sm:3'})
        )
    );
}


async function discoverFeaturesSupported() {
    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    const id = uuid.v4().substring(0, 8);

    const response = await _store.client.iqCaller.request(
        xml(
            'iq',
            {
                type: 'get',
                from: _store.whoami,
                to: _store.whoami,
                id: uuid.v4().substring(0, 8),
            },
            xml('query', {xmlns: 'http://jabber.org/protocol/disco#info'})
        ),
        30 * 1000, // 30 second timeout.
    );

    _store.features = {
        push_notifications: false,
        pubsub: false,
    };

    const query = response.getChild('query')

    if (!query) {
        return;
    }

    query.getChildren('feature').forEach(feature => {
        if (feature.attrs.var === 'urn:xmpp:push:0') {
            _store.features.push_notifications = true;
        }

        if (feature.attrs.var === 'http://jabber.org/protocol/pubsub') {
            _store.features.pubsub = true;
        }
    });

    // Do we have push notifications enabled for this device?
    if (_store.features.push_notifications) {
        enablePushNotifications();
    }

    // Can we list the pubsub nodes? We do not want to allow that.
    // if (_store.features.pubsub) {
    //     listPubsubNodes();
    // }
}

function connectToChat() {
    return new Promise((accept, reject) => {
        if (_store.client || _store.inhibitConnect) {
            return accept();
        }

        // Make sure we can't run this function again right away.
        _store.inhibitConnect = true;

        AuthStore.fetch(getConfig('users_api') + '/get-chat-token?embed=roster', {soft_fail: true}).then(
            async response => {
                _store.roster = response.roster || [];
                _store.notify_host = response.notify_host;

                // We web- concat'd with the first 8 chars of the instance_id (from environment config)
                const prefix = window.cordova ? 'app-' : 'web-';

                const xmpp = _store.client = client({
                    service: response.service_uri,
                    domain: response.domain,

                    resource: prefix + getConfig('instance_id').substring(0, 8),
                    username: response.jid,
                    password: response.token,
                });

                xmpp.on('error', err => {
                    console.error('❌', err.toString());

                    // If we're already retrying, don't try again.
                    if (_store.retrying) {
                        return;
                    }

                    _store.retrying = true;

                    // Immediately stop and kill the connection.
                    // We don't like @xmpp's silly retry logic.
                    xmpp.stop().catch(console.error);

                    // Do not attempt after more than 10 tries
                    if (_store.retry >= 10) {
                        _store.inhibitConnect = true;
                        _store.client = null;
                        console.log('🆘 failed to connect after ' + _store.retry + ' tries');
                        return;
                    }

                    console.log('❇️ retrying in 10 seconds...');

                    // We only ever want one of these running at a time.
                    if (_store.retryTimeout) {
                        clearTimeout(_store.retryTimeout);
                    }

                    // wait 10 seconds and try again, up to 10 times.
                    _store.retryTimeout = setTimeout(() => {
                        _store.retryTimeout = null;
                        _store.inhibitConnect = false;
                        _store.client = null;
                        _store.retrying = false;
                        _store.retry++;
                        connectToChat();
                    }, 10000);
                });

// xmpp.on('offline', () => {
//     console.log('⏹', 'offline')
// });

// xmpp.on('input', input => {
//     console.debug('✅', input)
// });

// xmpp.on('output', output => {
//     console.debug('🅾️', output)
// });

                xmpp.on('online', async address => {
                    console.log('🤖 goliath online as', address.toString());

                    _store.whoami = getJid(address.toString());

                    setPresence('available');
                    enableMessageCarbons();
                    // enableStreamManagement();
                    initializeArchive();
                    debounceEmitChange();
                    discoverFeaturesSupported();

                });

                xmpp.on('offline', () => {
                    console.log('🤖 goliath shutdown');
                });

                // Debug
                xmpp.on('status', status => {
                    _store.status = status;

                    // console.debug('💠', status);
                    debounceEmitChange();
                });

                xmpp.on('stanza', onReceiveStanza);

                await xmpp.start().catch(console.error);

                accept();

            },
            error => {
                // If we error'd getting our chat token, reset our inhibitor
                _store.inhibitConnect = false;
            }
        );
    });
}

function disconnectFromChat() {
    return new Promise((accept, reject) => {
        if (!_store.client) {
            return accept();
        }

        _store.client.stop().then(accept).catch(console.error);

        _store.inhibitConnect = false;
        _store.client = null;
    });
}

function sendPrivateMessage(recipient, messageText, attachments) {
    return new Promise(async (accept, reject) => {
        const chat = ChatStore.getChatWithJid(recipient);

        if (_store.status !== 'online' || chat.flags.fake || !_store.client) {
            return reject();
        }

        const id = uuid.v4().substring(0, 8);

        chat.messages.push({
            id,
            to: recipient,
            from: _store.whoami,
            timestamp: moment(),
            messageText: messageText,
            send_status: 'sent',
            attachments: (attachments || []).map(({uuid, filename, mime_type, image_width, image_height}) => (
                {uuid, filename, mime_type, image_width, image_height}
            )),
        });

        // Construct the chat message XML
        const messageXml = xml(
            'message',
            {type: 'chat', to: recipient, from: _store.whoami, id},
            xml('body', {}, messageText),
            xml('markable', {xmlns: 'urn:xmpp:chat-markers:0'}),
            xml('store', {xmlns: 'urn:xmpp:hints'}),
            xml('request', {xmlns: 'urn:xmpp:receipts'})
        );

        // Do we have any attachments we need to add?
        if (attachments && attachments.length) {
            attachments.forEach(file => {
                const { uuid, filename, mime_type, image_width, image_height } = file;

                messageXml.append(xml('attachment', {uuid, filename, mime_type, image_width, image_height}));
            });
        }

        await _store.client.send(messageXml).catch(error => console.log(error));

        return accept();
    });
}

/**
 * Creates and issues a query for archived messages before a given ID.
 *
 * @param  {string} recipient JID of with whom we are chatting.
 * @param  {strinng} beforeId  The id of the message to retrieve immediately before.
 * @param  {Number} limit     The number of items to request total
 */
const loadChatArchiveBefore = (recipient, beforeId = null, limit = 20) => {
    const chat = ChatStore.getChatWithJid(recipient, 'chat');

    if (!chat) {
        return;
    }

    if (_store.status !== 'online' || chat.flags.fake || !_store.client) {
        return;
    }

    // If there's already a query in progress for this chat thread, do not send another one
    if (chat.queryid) {
        return;
    }

    // Generate an ID, maybe we'll need to use this for keeping state or something.
    const id = uuid.v4().substring(0, 8);
    chat.queryid = uuid.v4().substring(0, 8);

    const iqXml = xml(
        'iq',
        {type: 'set', id},
        xml(
            'query',
            {xmlns: 'urn:xmpp:mam:0', queryid: chat.queryid},
            xml('x', {xmlns: 'jabber:x:data', type: 'submit'},
                xml('field', {var: 'FORM_TYPE', type: 'hidden'}, xml('value', {}, 'urn:xmpp:mam:0')),
                xml('field', {var: 'with'}, xml('value', {}, recipient))
            ),
            xml('set', {xmlns: 'http://jabber.org/protocol/rsm'},
                xml('max', {}, limit),
                (beforeId ? xml('before', {}, beforeId) : xml('before')),
            )
        )
    );

    try {
        _store.client.send(iqXml);
    } catch (exp) {
        // Log these exceptions and do not crash the page...
        Analytics.trackChatException(exp);
    }
}

/** Performs the initial  */
function initializeArchive() {
    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    // Generate an ID, maybe we'll need to use this for keeping state or something.
    const id = uuid.v4().substring(0, 8);
    const queryid = uuid.v4().substring(0, 8);

    const iqXml = xml(
        'iq',
        {type: 'set', id},
        xml(
            'query',
            {xmlns: 'urn:xmpp:mam:0', queryid: queryid},
            xml('set', {xmlns: 'http://jabber.org/protocol/rsm'},
                xml('max', {}, 100),
                xml('before'),
            )
        )
    );

    _store.client.send(iqXml);
}

async function markChatAsRead(withJid, type) {
    const chat = findOrCreateChatByWithAndType(withJid, type);

    if (_store.status !== 'online' || !_store.client) {
        return;
    }

    if (chat.flags.fake) {
        store.set("prep-ahead-read-marker", moment().format(), new Date().getTime() + 1000 * 3600 * 8);
        return;
    }

    // Do we need to emit a change?
    if (chat.totals.unread > 0) {
        chat.markers.read = moment();

        // Find the latest message in the chat that's not from me.
        const incoming = chat.messages.filter(m => m.from !== _store.whoami);
        const latest = incoming.length > 0 ? incoming[incoming.length - 1] : null;

        // Send a chat marker to the server for the last message in the list
        if (latest && _store.status === 'online' && !chat.flags.fake) {
            let id = uuid.v4().substring(0, 8);
            const messageXml = xml('message', {from: _store.whoami, to: latest.from, id},
                xml('displayed', {xmlns: 'urn:xmpp:chat-markers:0', id: latest.id}),
                xml('store', {xmlns: 'urn:xmpp:hints'}),
            );

            _store.client.send(messageXml);

            // Let arnold know too
            id = uuid.v4().substring(0, 8);
            await _store.client.iqCaller.request(
                xml('iq', {type: 'set', to: _store.notify_host, id},
                    xml('query', {xmlns: 'urn:xmpp:notify-marker:0'},
                        xml('with', {}, withJid),
                    )
                )
            );
        }

        syncMarkerTotals(chat);

        debounceEmitChange();
    }
}


async function markChatAsDelivered(withJid, type) {
    const chat = findOrCreateChatByWithAndType(withJid, type);

    if (_store.status !== 'online' || !_store.client || chat.flags.fake) {
        return;
    }

    // Find the latest message in the chat that's not from me.
    const incoming = chat.messages.filter(m => m.from !== _store.whoami);
    const latest = incoming.length > 0 ? incoming[incoming.length - 1] : null;

    // Send a chat marker to the server for the last message in the list
    if (latest && _store.status === 'online' && !chat.flags.fake) {
        let id = uuid.v4().substring(0, 8);
        const messageXml = xml('message', {from: _store.whoami, to: latest.from, id},
            xml('delivered', {xmlns: 'urn:xmpp:chat-markers:0', id: latest.id}),
            xml('store', {xmlns: 'urn:xmpp:hints'}),
        );

        _store.client.send(messageXml);

        // Let arnold know too
        id = uuid.v4().substring(0, 8);
        await _store.client.iqCaller.request(
            xml('iq', {type: 'set', to: _store.notify_host, id},
                xml('query', {xmlns: 'urn:xmpp:notify-marker:0'},
                    xml('with', {}, withJid),
                )
            )
        );
    }

    syncMarkerTotals(chat);

    debounceEmitChange();
}

AppDispatcher.register((payload) => {
    switch (payload.action.actionType) {
        case ChatConstants.CHAT_CONNECT:
            connectToChat().then(() => {
                ChatStore.emitChange();
            });
            break;

        case ChatConstants.CHAT_DISCONNECT:
            disconnectFromChat().then(() => {
                ChatStore.emitChange();
            });
            break;

        case ChatConstants.CHAT_SEND_PRIVATE_MESSAGE:
            sendPrivateMessage(payload.action.recipient, payload.action.messageText, payload.action.attachments).then(() => {
                ChatStore.emitChange();
            });
            break;

        case ChatConstants.CHAT_UPDATE_CHAT:
            syncMarkerTotals(payload.action.chat);
            debounceEmitChange();
            break;

        case ChatConstants.CHAT_LOAD_BEFORE:
            // beforeId can be null;
            loadChatArchiveBefore(payload.action.recipient, payload.action.beforeId);
            break;

        case ChatConstants.CHAT_MARK_AS_READ:
            markChatAsRead(payload.action.jid, payload.action.type);
            break;

        case UserConstants.USER_COMPLETE_LOGIN:
            connectToChat().then(() => {
                ChatStore.emitChange();
            });
            break;

        case UserConstants.USER_LOGOUT:
            disconnectFromChat().then(() => {
                ChatStore.emitChange();
            });
            break;
    }
});
