import React, { useEffect } from 'react';
import Button from './Button.react';
import './Prompt.scss';

const Prompt = ({ children, buttons, onClose }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="el-prompt">
            <div className="overlay" onClick={onClose}>
                <div className="card" onClick={(e) => e.stopPropagation()}>
                    <div className="content">{children}</div>
                    <div className="buttons">
                        {buttons.map((btn, index) => (
                            <Button key={index} {...btn}>
                                {btn.label}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prompt;
