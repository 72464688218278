'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TagGroup from './TagGroup.react';

export default class MealTypes extends Component {
    render() {
        return (
            <TagGroup {...this.props} translateTagMap={{'Main Dish': 'Dinner'}} />
        );
    }
}
