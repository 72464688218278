'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DrawerMenu from '../../pro/components/Widgets/DrawerMenu.react';
import Analytics from '../../utils/Analytics';
import { getMealInfo } from '../../utils/Meals';
import PushNotificationPrompt from '@components/Widgets/PushNotificationPrompt.react';

import './SwapMenu.scss';

export default class SwapMenu extends Component {
    static propTypes = {
        options: PropTypes.object,
        icon: PropTypes.string,
    };

    static defaultProps = {
        options: {},
    };

    static contextTypes = {
        user: PropTypes.object,
        recipes: PropTypes.object,
        foods: PropTypes.object,

        startReplaceMeal: PropTypes.func,
    };

    state = {
        showPushNotificationPrompt: false,
    };

    onOpenMenu = () => {
        const { primary } = this.props;
        const { recipes, foods, user } = this.context;

        const mealInfo = getMealInfo(primary, recipes, foods);

        Analytics.startSwapMeal({
            'Meal UUID': primary.uuid,
            'Meal Type': primary.meal,
            Date: primary.date,
            'Meal Name': mealInfo.mealName,
            'Product Type': mealInfo.productType,
            'Brand Name': mealInfo.brandName,
        });

        this.setState({ showPushNotificationPrompt: true });
    };

    checkForOnlyOneSwapOption = () => {
        const { primary, options, icon, className, button } = this.props;
        const { user, startReplaceMeal } = this.context;
        const { inhibit_add_swap_modes = [] } = user?.preferences || {};

        const addSwapModes = [
            'smart-choices',
            'restaurants',
            'favorites',
            'ingredients',
            'browser',
            'use-own-recipe',
            'create-custom',
        ];
        const allowedModes = addSwapModes.filter((mode) => !inhibit_add_swap_modes?.includes(mode));

        if (allowedModes.length == 1) {
            switch (allowedModes[0]) {
                case 'smart-choices':
                    startReplaceMeal(primary, { ...options, defaultMode: 'smart-choices', allowedModes });
                    break;
                case 'restaurants':
                    startReplaceMeal(primary, { ...options, defaultMode: 'restaurants', allowedModes });
                    break;
                case 'favorites':
                    startReplaceMeal(primary, { ...options, defaultMode: 'favorites', allowedModes });
                    break;
                case 'ingredients':
                    startReplaceMeal(primary, { ...options, defaultMode: 'ingredients', allowedModes });
                    break;
                case 'browser':
                    startReplaceMeal(primary, { ...options, defaultMode: 'browser', allowedModes });
                    break;
                case 'use-own-recipe':
                    startReplaceMeal(primary, { ...options, defaultMode: 'use-own-recipe', allowedModes });
                    break;
                case 'create-custom':
                    startReplaceMeal(primary, { ...options, defaultMode: 'create-custom', allowedModes });
                    break;
                default:
                    break;
            }

            return true;
        }

        return false;
    };

    render() {
        const { primary, options, icon, className, button } = this.props;
        const { user, startReplaceMeal } = this.context;
        const { inhibit_add_swap_modes = [] } = user?.preferences || {};
        const { showPushNotificationPrompt } = this.state;

        const addSwapModes = [
            'smart-choices',
            'restaurants',
            'favorites',
            'ingredients',
            'browser',
            'use-own-recipe',
            'create-custom',
        ];
        const allowedModes = addSwapModes.filter((mode) => !inhibit_add_swap_modes?.includes(mode));
        const renderMenuItems = {
            'smart-choices': (
                <button
                    key={'smart-choices'}
                    className="menu-item smart-choices"
                    onClick={() =>
                        startReplaceMeal(primary, { ...options, defaultMode: 'smart-choices', allowedModes })
                    }
                >
                    Smart Choices <i className="icon-lightbulb" />
                </button>
            ),
            restaurants: (
                <button
                    key={'restaurants'}
                    className="menu-item eat-out-instead"
                    onClick={() => startReplaceMeal(primary, { ...options, defaultMode: 'restaurants', allowedModes })}
                >
                    Restaurant Meal <i className="icon-restaurant-meals" />
                </button>
            ),
            favorites: (
                <button
                    key={'favorites'}
                    className="menu-item favorites"
                    onClick={() => startReplaceMeal(primary, { ...options, defaultMode: 'favorites', allowedModes })}
                >
                    Choose from favorites <i className="icon-heart2" />
                </button>
            ),
            ingredients: (
                <button
                    key={'ingredients'}
                    className="menu-item search-by-ingredients"
                    onClick={() => startReplaceMeal(primary, { ...options, defaultMode: 'ingredients', allowedModes })}
                >
                    Search by Ingredients <i className="icon-list1" />
                </button>
            ),
            browser: (
                <button
                    key={'browser'}
                    className="menu-item search"
                    onClick={() =>
                        startReplaceMeal(primary, {
                            ...options,
                            defaultMode: 'browser',
                            allowedModes,
                        })
                    }
                >
                    Search Foods and Recipes <i className="icon-search5" />
                </button>
            ),
            'use-own-recipe': (
                <button
                    key={'use-own-recipe'}
                    className="menu-item search"
                    onClick={() =>
                        startReplaceMeal(primary, { ...options, defaultMode: 'use-own-recipe', allowedModes })
                    }
                >
                    Use My Own Recipe <i className="icon-add-circle-outline2" />
                </button>
            ),
            'create-custom': (
                <button
                    key={'create-custom'}
                    className="menu-item search"
                    onClick={() =>
                        startReplaceMeal(primary, { ...options, defaultMode: 'create-custom', allowedModes })
                    }
                >
                    Create Custom Food <i className="icon-paper" />
                </button>
            ),
        };

        return (
            <DrawerMenu
                button={
                    button || (
                        <>
                            {icon ? <i className={icon} /> : null}
                            <em>swap</em>
                        </>
                    )
                }
                className={classNames('swap-menu-btn', className)}
                modalClassName="feed-swap-menu"
                menuTitle="Swap your Meal With"
                onOpenMenu={this.onOpenMenu}
                checkForOnlyOneSwapOption={this.checkForOnlyOneSwapOption}
            >
                <div className="feed-menu-content">
                    {allowedModes.map((mode) => {
                        return renderMenuItems[mode];
                    })}
                </div>
                {showPushNotificationPrompt && (
                    <PushNotificationPrompt
                        delay={3000}
                        onClose={() => {
                            this.setState({ showPushNotificationPrompt: false });
                        }}
                        triggeredFrom={'SwapMenu'}
                    />
                )}
            </DrawerMenu>
        );
    }
}
