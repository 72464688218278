'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import store from 'store';
import { Link } from 'react-router';
import classNames from 'classnames';
import * as Sentry from '@sentry/react';

import TermsModal from './Onboarding/TermsModal.react';
import PrivacyModal from './Onboarding/PrivacyModal.react';
import Select from '../../pro/components/Widgets/Select.react';

import AmazonLogin from './AmazonLogin.react';
import Analytics from '../../utils/Analytics';
import UserActions from '../../actions/UserActions';
import AuthActions from '../../actions/AuthActions';
import { getConfig, getMref } from '../../utils/Env';

import './LoginForm.scss';

export default class LoginForm extends Component {
    static propTypes = {
        callToAction: PropTypes.string,
        defaultMode: PropTypes.string,
        onCompleteSignIn: PropTypes.func,
        onCompleteSignUp: PropTypes.func,
        signUpProfile: PropTypes.object,
        signUpCTA: PropTypes.string,
        signupFormClassNames: PropTypes.node,
        signupFormTitleText: PropTypes.node,
        brochureTitleText: PropTypes.node,
        brochureHeadingText: PropTypes.node,
        brochurePointsText: PropTypes.array,
        hideIDPs: PropTypes.bool, // hide identity provider buttons
        hideSignUp: PropTypes.bool, // hide sign-up feature
        hideRetrieveInvite: PropTypes.bool,
        backToSignIn: PropTypes.string,
        scrollToTop: PropTypes.func,
        closeModal: PropTypes.func,
        returnToLanding: PropTypes.string,
        signupLinkSwitchesModes: PropTypes.bool,
        formSubtitleText: PropTypes.string,

        showProPracticeTypes: PropTypes.bool,
        defaultPracticeType: PropTypes.string,
        retrieveInviteText: PropTypes.node,
        retrieveInviteCta: PropTypes.string,

        forgotPasswordContext: PropTypes.string,
    };

    static defaultProps = {
        formSubtitleText: 'Sign in to access your EatLove account',
        defaultMode: 'sign-in',
        hideIDPs: false,
        signUpCTA: 'Sign Up',
        signupFormClassNames: '',
        signupFormTitleText: 'Create an account to see your grocery list and start your new personalized meal plan.*',
        brochureTitleText: "EatLove's Custom Meal Plan Advisor gets you eating healthy.",
        brochureHeadingText: 'Complete with:',
        brochurePointsText: [
            'Personalized meal plans matched to your unique needs and health goals.',
            'Delicious recipes from renowned food experts.',
            'Optimized grocery list to minimize waste.',
            'Optional home delivery from AmazonFresh.',
        ],
        hideRetrieveInvite: false,
        backToSignIn: 'Back to Sign in',
        defaultPracticeType: 'consumer',

        retrieveInviteText: 'Invited by your dietitian or fitness professional and unsure of your login details?',
        retrieveInviteCta: 'Retrieve Invitation',
    };

    static contextTypes = {
        router: PropTypes.object,
        isCordova: PropTypes.bool,
        isPro: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        const { defaultMode = 'sign-in' } = props;

        this.state = {
            error: false,
            processing: false,
            mode: defaultMode,

            showTermsModal: false,
            showPrivacyModal: false,

            practice_type: props.defaultPracticeType,
        };

        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.password2Ref = React.createRef();
        this.tfaTokenRef = React.createRef();
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.focusEmail();
        }, 50);

        const { defaultMode } = this.props;

        if (defaultMode == 'sign-in') {
            Analytics.startSignIn();
        }

        if (defaultMode == 'sign-up') {
            Analytics.startSignUp();
        }
    };

    focusEmail = () => {
        const { isCordova } = this.context;
        if (!this.emailRef.current || isCordova) {
            return;
        }

        this.emailRef.current.focus();
    };

    submitLogin = (e) => {
        e && e.preventDefault && e.preventDefault();

        const email = this.emailRef.current.value.trim();
        const password = this.passwordRef.current.value;

        if (!email) {
            this.setState({ alert: 'Please enter your email address' });
            return;
        }

        if (!password) {
            this.setState({ alert: 'Please enter your password or phrase' });
            return;
        }

        this.setState({ processing: true });

        AuthActions.loginWithCredentials(email, password).then(this.handleSuccess, this.handleLoginError);
    };

    submitLoginWithToken = (e) => {
        e && e.preventDefault && e.preventDefault();

        const { email, password, isPrivate } = this.state;

        const tfaToken = this.tfaTokenRef.current.value.trim();

        if (!tfaToken) {
            this.setState({ error: 'Please enter the code from your Google Authenticator app' });
            return;
        }

        this.setState({ processing: true });

        AuthActions.loginWithCredentials(email, password, tfaToken, isPrivate).then(
            this.handleSuccess,
            this.handleLoginError
        );
    };

    submitSignUp = (e) => {
        const { practice_type } = this.state;
        e && e.preventDefault && e.preventDefault();

        const email = this.emailRef.current.value.trim();
        const password = this.passwordRef.current.value;
        const password2 = this.password2Ref.current.value;

        const { signUpProfile } = this.props;
        let isValid = true;
        let error = '';

        if (!email) {
            isValid = false;
            error = 'Please enter your email address';
        }

        // Check to make sure passwords match
        if (password !== password2) {
            isValid = false;
            error = 'Passwords do not match';
        }

        if (password.length < 5) {
            isValid = false;
            error = 'Passwords must be at least 5 characters';
        }

        if (!isValid) {
            this.setState({ error });
            Analytics.registerError({ Email: email, 'Error Message': error });
            return;
        }

        this.setState({ processing: true, error: false });
        const { ...rest } = signUpProfile || {};
        rest.email = email;
        rest.password = password;
        rest.merchant_ref = getMref();
        rest.practice_type = practice_type;

        AuthActions.register(rest).then(this.handleSuccess, this.handleSignupError);
    };

    submitAmazonLogin = (response) => {
        if (!response.access_token) {
            return;
        }

        // We need to login or create an account...
        this.setState({ processing: true });

        AuthActions.loginWithAmazon({
            ...response, // Just send the entire response from amazon over...
            merchant_ref: getMref(),
        }).then(this.handleSuccess, this.handleLoginError);
    };

    handleSuccess = ({ auth, user }) => {
        // Do we have a signup profile? If so, we need to save some values
        const { signUpProfile, onCompleteSignIn, onCompleteSignUp } = this.props;

        $(window).scrollTop(0);

        if (!auth.new_user) {
            onCompleteSignIn && onCompleteSignIn(user);

            Analytics.login();

            return;
        }

        const {
            conditions = [],
            prescriptions = [],
            preferences = { leftovers_enabled: true, max_leftover_days: 1 },
            family = [],
        } = signUpProfile || {};

        onCompleteSignUp && onCompleteSignUp(user);

        UserActions.updateSpecificMeta({ conditions, prescriptions, preferences, family });
    };

    handleLoginError = (error) => {
        const { mode } = this.state;
        const errorMsg = (error && error.message) || error || 'unknown error';

        const email = (this.emailRef.current && this.emailRef.current.value.trim()) || this.state.email;
        const password = (this.passwordRef.current && this.passwordRef.current.value) || this.state.password;

        // The user has enabled Two-Factor auth and we need to supply
        if (errorMsg === 'A valid Two-Factor token is required' && mode === 'sign-in') {
            this.setState({ email, password, mode: 'two-factor', processing: false, error: false });
            return;
        }

        Analytics.loginError({
            Email: email,
            'Error Message': errorMsg,
        });

        this.setState({
            processing: false,
            error: errorMsg,
        });
    };

    handleSignupError = (error) => {
        let errorMsg = (error && error.message) || error || 'unknown error';

        const errorMap = {
            'email - String is not a valid email address': 'Please enter a valid email address.',
            'email - Email address already in use': 'Email address already in use. Please sign-in instead.',
            'unknown error': 'An unknown error occured. Please try again later.',
        };

        Analytics.registerError({
            Email: this.emailRef.current.value.trim(),
            'Error Message': errorMsg,
        });

        this.setState({
            processing: false,
            error: errorMap[errorMsg] || errorMsg,
        });
    };

    sendForgotPassword = (e) => {
        const { forgotPasswordContext } = this.props;
        e && e.preventDefault && e.preventDefault();
        this.setState({ processing: true });

        const email = this.emailRef.current.value;

        if (!email) {
            this.setState({
                error: 'Please enter an email address',
                processing: false,
            });

            return;
        }

        Analytics.submitRecoverAccountEmailAddress({ Email: email});

        fetch('https://' + getConfig('self_host') + '/forgot-password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=forgot/password/1' },
            body: JSON.stringify({ email, context: forgotPasswordContext }),
        }).then(
            (response) => {
                if (response.ok) {
                    this.setState({
                        mode: 'forgot-password-sent',
                        error: null,
                        processing: false,
                    });
                } else {
                    this.setState({
                        error: 'Sorry, something went wrong. Please try again later',
                        processing: false,
                    });
                }
            },
            (error) => {
                Sentry.captureException(error);
                this.setState({
                    error: 'Sorry, something went wrong. Please try again later',
                    processing: false,
                });
            }
        );
    };

    sendRetrieveInvite = (e) => {
        const { forgotPasswordContext } = this.props;
        e && e.preventDefault && e.preventDefault();
        this.setState({ processing: true });

        const email = this.emailRef.current.value;

        if (!email) {
            this.setState({
                error: 'Please enter an email address',
                processing: false,
            });

            return;
        }

        fetch('https://' + getConfig('self_host') + '/forgot-password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; schema=forgot/password/1' },
            body: JSON.stringify({ email, context: forgotPasswordContext }),
        }).then(
            (response) => {
                if (response.ok) {
                    this.setState({
                        mode: 'retrieve-invite-sent',
                        error: null,
                        processing: false,
                    });
                } else {
                    this.setState({
                        error: 'Sorry, something went wrong. Please try again later',
                        processing: false,
                    });
                }
            },
            (error) => {
                Sentry.captureException(error);
                this.setState({
                    error: 'Sorry, something went wrong. Please try again later',
                    processing: false,
                });
            }
        );
    };

    closeModal = () => {
        this.setState({ showTermsModal: false, showPrivacyModal: false });
    };

    showPrivacyPolicy = () => {
        this.setState({ showPrivacyModal: true });
    };

    showTerms = () => {
        this.setState({ showTermsModal: true });
    };

    switchModes = (mode, callback) => {
        const { scrollToTop } = this.props;

        if (mode == "forgot-password") {
            Analytics.clickForgotPassword();
        }

        this.setState({ mode, error: false }, () => {
            typeof callback === 'function' && callback();
            typeof scrollToTop === 'function' && scrollToTop();
        });
    };

    renderPrivacyModal = () => {
        const { showPrivacyModal } = this.state;

        if (!showPrivacyModal) {
            return;
        }

        return <PrivacyModal closeModal={this.closeModal} />;
    };

    renderTermsModal = () => {
        const { showTermsModal } = this.state;

        if (!showTermsModal) {
            return;
        }

        return <TermsModal closeModal={this.closeModal} />;
    };

    renderRetrieveInviteSent = () => {
        const { backToSignIn } = this.props;

        return (
            <section className="login-form">
                {this.renderFormHeader()}
                <section className="form-container forgot-password-sent">
                    <header>
                        <h2>
                            <i className="icon-email3" />
                            Invitation Resent
                        </h2>
                    </header>
                    <p>You&apos;re invitation has been resent.</p>
                    <p>Don&apos;t forget to check your spam folder!</p>
                    <footer>
                        <a
                            className="back-to-sign-in-btn sub-action-btn"
                            onClick={() => this.setState({ mode: 'sign-in' }, this.focusEmail)}
                        >
                            {backToSignIn}
                        </a>
                    </footer>
                </section>
            </section>
        );
    };

    renderForgotPasswordSent = () => {
        const { backToSignIn } = this.props;

        return (
            <section className="login-form">
                {this.renderFormHeader()}
                <section className="form-container forgot-password-sent">
                    <header>
                        <h2>
                            <i className="icon-email3" />
                            Forgot Password
                        </h2>
                    </header>
                    <p>Instructions for how to recover your account have been sent to your email inbox.</p>
                    <p>Don&apos;t forget to check your spam folder!</p>
                    <footer>
                        <a
                            className="back-to-sign-in-btn sub-action-btn"
                            onClick={() => this.setState({ mode: 'sign-in' }, this.focusEmail)}
                        >
                            {backToSignIn}
                        </a>
                    </footer>
                </section>
            </section>
        );
    };

    renderForgotPassword = () => {
        const { error, processing } = this.state;
        const { backToSignIn } = this.props;

        const defaultEmail = store.get('default-email') || '';

        return (
            <section className="login-form forgot-password-form">
                {this.renderFormHeader()}
                <section className="form-container">
                    <header>
                        <p>Enter your email address and we will send you instructions to reset your password.</p>
                    </header>

                    <form onSubmit={this.sendForgotPassword}>
                        <div className="with-label email">
                            <label>Email address</label>
                            <input
                                type="email"
                                label="E-mail"
                                name="email"
                                defaultValue={defaultEmail}
                                autoCapitalize="off"
                                spellCheck="false"
                                autoComplete="email"
                                disabled={processing}
                                ref={this.emailRef}
                            />
                        </div>

                        <footer>
                            {error ? <p className="error-msg">{error}</p> : null}
                            <button
                                type="submit"
                                className="login-btn"
                                onClick={this.sendForgotPassword}
                                disabled={processing}
                            >
                                {processing ? 'Working...' : 'Recover Account'}
                            </button>

                            <a
                                className="back-to-sign-in-btn sub-action-btn"
                                onClick={() => this.switchModes('sign-in', this.focusEmail)}
                            >
                                {backToSignIn}
                            </a>
                        </footer>
                    </form>
                </section>
            </section>
        );
    };

    renderRetrieveInvite = () => {
        const { isCordova } = this.context;
        const { error, processing } = this.state;
        const { backToSignIn, retrieveInviteText, retrieveInviteCta } = this.props;

        const defaultEmail = store.get('default-email') || '';

        return (
            <section className="login-form retrieve-invite-form">
                {this.renderFormHeader()}

                <section className="form-container">
                    <header>
                        <img src="https://static.chewba.info/images/header-logo-gray-green-2017-07-03.png" />
                        <h2>{retrieveInviteText}</h2>
                    </header>

                    <form onSubmit={this.sendRetrieveInvite}>
                        <div className="with-label email">
                            <label>Email address</label>
                            <input
                                type="email"
                                label="E-mail"
                                name="email"
                                defaultValue={defaultEmail}
                                autoCapitalize="off"
                                spellCheck="false"
                                autoComplete="email"
                                disabled={processing}
                                ref={this.emailRef}
                            />
                        </div>

                        <footer>
                            {error ? <p className="error-msg">{error}</p> : null}
                            <button
                                type="submit"
                                className="login-btn"
                                onClick={this.sendRetrieveInvite}
                                disabled={processing}
                            >
                                {processing ? 'Working...' : retrieveInviteCta}
                            </button>

                            <a
                                className="back-to-sign-in-btn sub-action-btn"
                                onClick={() => this.switchModes('sign-in', this.focusEmail)}
                            >
                                {backToSignIn}
                            </a>
                        </footer>
                    </form>

                    {isCordova ? (
                        <div className="app-admonishment">
                            <i className="feather feather-alert-triangle" />

                            <p>
                                <strong>
                                    Our Downloadable mobile app is currently only available for professionally managed
                                    accounts.
                                </strong>
                            </p>

                            <p>BUT WAIT, good news: You can still experience the magic of EatLove by visiting</p>
                            <p>
                                <a target="_blank" href="https://www.eatlove.is" rel="noreferrer">
                                    www.eatlove.is
                                </a>
                            </p>
                            <p>on your mobile or desktop browser</p>
                        </div>
                    ) : null}
                </section>
            </section>
        );
    };

    onSelectPracticeType = (practice_type) => {
        if (practice_type === 'other') {
            this.setState({ practice_type: 'fitness', otherTypeSelected: true });
        } else {
            this.setState({ practice_type, otherTypeSelected: false });
        }
    };

    renderSignUpForm = () => {
        const {
            hideIDPs,
            signUpCTA,
            signupFormClassNames,
            signupFormTitleText,
            brochureTitleText,
            brochureHeadingText,
            brochurePointsText,
            showProPracticeTypes,
        } = this.props;
        const { error, processing, practice_type, otherTypeSelected } = this.state;

        const defaultEmail = store.get('default-email') || '';

        const practiceTypeOpts = [
            { label: 'Registered Dietitian or Health Professional', value: 'dietetics' },
            { label: 'Fitness Professional', value: 'fitness' },
            { label: 'None of the above', value: 'other' },
        ];

        let practiceType = otherTypeSelected ? 'other' : practice_type;

        return (
            <div className={classNames('login-form register-form', signupFormClassNames)}>
                {this.renderFormHeader()}
                <section className="form-container">
                    <header>
                        <h2>{signupFormTitleText}</h2>
                    </header>

                    <form onSubmit={this.submitSignUp}>
                        <div className="with-label email">
                            <label>Email Address</label>
                            <input
                                ref={this.emailRef}
                                placeholder="Enter your email address"
                                name="email"
                                defaultValue={defaultEmail}
                                autoCapitalize="off"
                                spellCheck="false"
                                autoComplete="email"
                                type="email"
                                disabled={processing}
                            />
                        </div>

                        <div className="with-label email">
                            <label>Create Password</label>
                            <input
                                ref={this.passwordRef}
                                type="password"
                                placeholder="Enter a password"
                                autoComplete="new-password"
                                name="password"
                                disabled={processing}
                            />
                        </div>

                        <div className="with-label email">
                            <label>Confirm Password</label>
                            <input
                                ref={this.password2Ref}
                                type="password"
                                autoComplete="new-password"
                                placeholder="Retype password exactly"
                                name="password2"
                                disabled={processing}
                            />
                        </div>

                        {showProPracticeTypes ? (
                            <div className="with-label pick-practice-type">
                                <label>I am a...</label>
                                <Select
                                    options={practiceTypeOpts}
                                    value={practiceType}
                                    onChange={this.onSelectPracticeType}
                                />
                            </div>
                        ) : null}

                        <footer>
                            {error ? <p className="error-msg">{error}</p> : null}

                            <button type="submit" className="login-btn" disabled={processing}>
                                {processing ? 'Registering...' : signUpCTA}
                            </button>
                        </footer>
                    </form>

                    <footer>
                        {!hideIDPs ? <hr /> : null}
                        {!hideIDPs ? <h2>OR</h2> : null}

                        {!hideIDPs ? (
                            <div>
                                <AmazonLogin callback={this.submitAmazonLogin} />
                            </div>
                        ) : null}

                        <p className="terms-privacy">
                            <em>*</em>By clicking above, you agree to EatLove’s
                            <br />
                            <a onClick={this.showPrivacyPolicy}>privacy policy</a> &{' '}
                            <a onClick={this.showTerms}>terms of service</a>
                        </p>

                        <p className="login-support-text">
                            Already have an account?
                            <a onClick={() => this.switchModes('sign-in')}>Click here to sign-in!</a>
                        </p>
                    </footer>
                </section>

                <section className="brochure-container">
                    <h2>{brochureTitleText}</h2>

                    <p>{brochureHeadingText}</p>
                    <ul>
                        {brochurePointsText.map((text, i) => (
                            <li key={i}>{text}</li>
                        ))}
                    </ul>

                    <i className="icon-leaf2 deco1" />
                    <i className="icon-leaf2 deco2" />
                    <i className="icon-leaf2 deco3" />
                </section>

                {this.renderPrivacyModal()}
                {this.renderTermsModal()}
            </div>
        );
    };

    renderTwoFactorForm = () => {
        const { processing, error, isPrivate } = this.state;

        return (
            <section className="login-form forgot-password-form">
                {this.renderFormHeader()}
                <section className="form-container">
                    <header>
                        <p>Please enter your Two-Factor login code:</p>
                    </header>

                    <form onSubmit={this.submitLoginWithToken}>
                        <div className="with-label two-factor-code">
                            <label>Two-Factor Code:</label>
                            <input
                                type="text"
                                label="Two-Factor token"
                                name="tfa_token"
                                autoCapitalize="off"
                                spellCheck="false"
                                autoComplete="off"
                                disabled={processing}
                                ref={this.tfaTokenRef}
                            />
                        </div>

                        <div className="with-label is-private">
                            <button
                                type="button"
                                className="checkbox-btn"
                                data-active={isPrivate}
                                onClick={() => this.setState({ isPrivate: !isPrivate })}
                            >
                                <span>
                                    This computer is private
                                    <br /> (do not ask for codes here again)
                                </span>
                            </button>
                        </div>

                        <footer>
                            {error ? <p className="error-msg">{error}</p> : null}

                            <button
                                type="submit"
                                className="login-btn"
                                onClick={this.submitLoginWithToken}
                                disabled={processing}
                            >
                                {processing ? 'Working...' : 'Sign in'}
                            </button>
                        </footer>
                    </form>
                </section>
            </section>
        );
    };

    renderFormHeader = () => {
        const { closeModal } = this.props;

        if (!closeModal) {
            return;
        }

        return (
            <header>
                <button className="close-modal-btn" onClick={closeModal}>
                    <i className="feather feather-arrow-left" />
                </button>
            </header>
        );
    };

    render() {
        const {
            signupLinkSwitchesModes = false,
            formSubtitleText,
            hideIDPs,
            hideSignUp,
            hideRetrieveInvite,
            returnToLanding,
            retrieveInviteText,
            retrieveInviteCta,
        } = this.props;
        const { error, processing, mode } = this.state;
        const { isPro } = this.context;

        if (mode === 'retrieve-invite-sent') {
            return this.renderRetrieveInviteSent();
        }

        if (mode === 'forgot-password-sent') {
            return this.renderForgotPasswordSent();
        }

        if (mode === 'forgot-password') {
            return this.renderForgotPassword();
        }

        if (mode === 'retrieve-invite') {
            return this.renderRetrieveInvite();
        }

        if (mode === 'sign-up') {
            return this.renderSignUpForm();
        }

        if (mode === 'two-factor') {
            return this.renderTwoFactorForm();
        }

        return (
            <div className="login-form existing-form">
                {this.renderFormHeader()}
                <section className="form-container">
                    <header>
                        <div className="logo-container">
                            <Link to="/">
                                <img src="https://static.chewba.info/images/header-logo-gray-green-2017-07-03.png" />
                            </Link>
                            {isPro ? <div className="pro-label">PRO</div> : null}
                        </div>

                        <h2>{formSubtitleText}</h2>

                        {returnToLanding ? (
                            <a className="return-to-lp" target="lp" href={returnToLanding}>
                                <i className="icon-chevron-left" />
                            </a>
                        ) : null}
                    </header>

                    <form onSubmit={this.submitLogin}>
                        <div className="with-label email">
                            <label htmlFor="email-address">Email Address</label>
                            <input
                                ref={this.emailRef}
                                name="email"
                                autoComplete="username"
                                autoCapitalize="off"
                                spellCheck="false"
                                type="email"
                                id="email-address"
                                disabled={processing}
                            />
                        </div>

                        <div className="with-label email">
                            <label htmlFor="password">Password</label>
                            <input
                                ref={this.passwordRef}
                                type="password"
                                autoComplete="current-password"
                                name="password"
                                id="password"
                                disabled={processing}
                            />
                        </div>

                        <footer>
                            {error ? <p className="error-msg">{error}</p> : null}

                            <button
                                type="submit"
                                className="login-btn"
                                disabled={processing}
                                onClick={processing ? null : this.submitLogin}
                            >
                                {processing ? 'Signing in...' : 'Sign In'}
                            </button>
                        </footer>

                        {!hideRetrieveInvite ? (
                            <p className="retrieve-invite">
                                {retrieveInviteText}
                                <a
                                    className="sub-action-btn"
                                    onClick={() => this.switchModes('retrieve-invite', this.focusEmail)}
                                >
                                    {retrieveInviteCta}
                                </a>
                            </p>
                        ) : null}

                        <div className="forgot-pwd-container">
                            <a
                                className="sub-action-btn"
                                onClick={() => this.switchModes('forgot-password', this.focusEmail)}
                            >
                                Forgot Password
                            </a>
                        </div>
                    </form>

                    {!hideIDPs || !hideSignUp ? (
                        <footer>
                            {!hideIDPs ? <hr /> : null}
                            {!hideIDPs ? <h2>OR</h2> : null}

                            {!hideIDPs ? (
                                <div>
                                    <AmazonLogin callback={this.submitAmazonLogin} />
                                </div>
                            ) : null}

                            {!hideSignUp ? (
                                <p className="login-support-text">
                                    Don&apos;t have an account?
                                    {!signupLinkSwitchesModes ? (
                                        <Link to="/new-account">Sign up!</Link>
                                    ) : (
                                        <a onClick={() => this.switchModes('sign-up')}>Sign up!</a>
                                    )}
                                </p>
                            ) : null}
                        </footer>
                    ) : null}
                </section>
            </div>
        );
    }
}
