'use strict';

import { fetchDocumentsById } from './Content';
import indexBy from 'lodash.indexby';
import allUnits from '../tables/units';

export function getDetailsPrototype() {
    return {
        ingredients: [getIngredientGroupPrototype()],
        preparation: [getPrepGroupPrototype()],
    };
}

export function getIngredientPrototype() {
    return {
        ingredient: "",
        percent_yield: 100,
        measurement: {
            amount: 0,
            unit_of_measure: ""
        },
    };
}

export function getIngredientGroupPrototype() {
    return {
        title: '',
        items: [],
    };
}

export function getInstructionGroupPrototype() {
    return {
        text: "",
    };
}

export function getPrepGroupPrototype() {
    return {
        title: "",
        items: [getInstructionGroupPrototype()]
    };
}

export function ensureDetailsPopulated(details) {
    if (!details) {
        return getDetailsPrototype();
    }

    details.ingredients = details.ingredients || [];
    details.preparation = details.preparation || [];

    // Now make sure that every ingredient group and every instruction group has at least
    // one element
    details.ingredients = details.ingredients.map((group) => {
        group.items = group.items || [];
        return group;
    });

    details.preparation = details.preparation.map((group) => {
        group.items = group.items || [];
        return group;
    });

    return details;
}

export function getCookMethodFromFood(food, method) {
    if (!food.cooking_methods?.length) {
        return null;
    }

    return food.cooking_methods.find((cookMethod) => {
        if (cookMethod.description === method) {
            return cookMethod;
        }
    });
}

export function getIngredientFoodNutrients(ingredient, food) {
    let { grams, milliliters } = ingredient;
    const yld = ingredient.percent_yield ? (ingredient.percent_yield / 100) : 1;
    const cookMethod = ingredient.cooking?.method ? getCookMethodFromFood(food, ingredient.cooking.method) : null;

    if (!((grams || milliliters) && food.nutrients)) {
        return {};
    }

    const nutrFactors = indexBy(cookMethod?.factors || [], 'nutrno');
    const nutrients = {};

    const perServing = food.serving_unit === 'ml' ? food.milliliters_per_serving : food.grams_per_serving;
    const perIngredient = food.serving_unit === 'ml' ? milliliters : grams;

    Object.keys(food.nutrients.values).forEach(nutrNo => {
        let nutrFactor = nutrFactors[nutrNo];
        const value = food.nutrients.values[nutrNo];

        nutrients[nutrNo] = (value / perServing) * perIngredient * yld * (nutrFactor?.factor || 1);
    });

    return nutrients;
}

export function getIngredientSubrecipeNutrients(ingredient, subrecipe) {
    const grams = ingredient.grams || 0;
    const yld = ingredient.percent_yield ? (ingredient.percent_yield / 100) : 1;

    const gramsPerServing = subrecipe.grams_per_serving;

    if (!(grams && subrecipe.nutrients && subrecipe.nutrients.values && gramsPerServing)) {
        return {};
    }

    const nutrients = {};

    Object.keys(subrecipe.nutrients.values).forEach(nutrNo => {
        const value = subrecipe.nutrients.values[nutrNo];

        nutrients[nutrNo] = (value / gramsPerServing) * grams * yld;
    });

    return nutrients;
}

export function getIngredientNutrients(ingredient, foods, subrecipes) {
    if (!ingredient) {
        return {};
    }

    if (ingredient.food && ingredient.food.uuid && foods[ingredient.food.uuid]) {
        return getIngredientFoodNutrients(ingredient, foods[ingredient.food.uuid]);
    }

    if (ingredient.recipe && ingredient.recipe.uuid && subrecipes[ingredient.recipe.uuid]) {
        return getIngredientSubrecipeNutrients(ingredient, subrecipes[ingredient.recipe.uuid])
    }

    return {};
}

export function flattenIngredients(details) {
    const ingredients = [];

    Object.keys(details).map(key => details[key]).forEach(detail => {
        if (!detail.ingredients) {
            return;
        }

        detail.ingredients.forEach(group => {
            if (!group.items) {
                return;
            }

            group.items.forEach(ingredient => {
                ingredients.push(ingredient);
            });
        });
    });

    return ingredients;
}

export function getFoodIdsFromIngredients(ingredients) {
    const foodIds = [];

    ingredients.forEach(ingredient => {
        if (ingredient.food?.uuid) {
            foodIds.push(ingredient.food.uuid);
        }

        if (ingredient.recipe?.uuid) {
            foodIds.push(ingredient.recipe.uuid);
            foodIds.push(ingredient.recipe.details);
        }

        ingredient.alternates?.forEach(alternate => {
            if (alternate.food?.uuid) {
                foodIds.push(alternate.food.uuid);
            }

            if (alternate.recipe?.uuid) {
                foodIds.push(alternate.recipe.uuid);
                foodIds.push(alternate.recipe.details);
            }
        });
    });

    return foodIds;
}

export async function getAssetsForRecipe(recipe, details = null) {
    if (!details && typeof recipe.details === 'string') {
        const detailsDocs = await fetchDocumentsById([recipe.details]);
        details = detailsDocs[0];
    }

    if (details) {
        const firstUuidsToLoad = getFoodIdsFromIngredients(flattenIngredients({details}));
        const first = await fetchDocumentsById(firstUuidsToLoad)

        let foods      = indexBy(first.filter(d => d && d.type == 'food'), 'uuid');
        let subrecipes = indexBy(first.filter(d => d && d.type == 'recipe'), 'uuid');
        let subdetails = indexBy(first.filter(d => d && d.type == 'recipe_details'), 'uuid');

        const secondUuidsToLoad = getFoodIdsFromIngredients(flattenIngredients(subdetails));
        Object.keys(foods).forEach(puuid => {
            const food = foods[puuid];

            if (food && food.brand_uuid && !secondUuidsToLoad.includes(food.brand_uuid)) {
                secondUuidsToLoad.push(food.brand_uuid);
            }
        });

        let second = await fetchDocumentsById(secondUuidsToLoad)
        let brands = indexBy(second.filter(d => d.type === 'brand'), 'uuid');
        foods = {...foods, ...indexBy(second.filter(d => d.type == 'food'), 'uuid')};

        // Always add our main details array into subdetails too, this is weird I know, but it's just in case.
        subdetails[details.uuid] = details;

        return { details, foods, subrecipes, subdetails, brands, products: {} };
    }

    return { details }
}


export function getRecipeUnits(recipe, force = false) {
    let { language = 'en', grams_per_serving, milliliters_per_serving } = recipe;

    let units = [
        {
            description: 'serving',
            amount: 1,
            grams: grams_per_serving,
            milliliters: milliliters_per_serving,
        },
        {
            description: 'batch',
            amount: 1,
            grams: grams_per_serving * recipe.servings,
            milliliters: milliliters_per_serving * recipe.servings,
        },
    ];

    // Compute the density of the recipe yield in grams per milliliter
    const density =
        typeof milliliters_per_serving === 'number' && !isNaN(milliliters_per_serving) && milliliters_per_serving != 0
            ? grams_per_serving / milliliters_per_serving
            : undefined;

    allUnits[language]
        .filter((u) => ['mass', 'weight'].includes(u.type))
        .forEach((u) => {
            units.push({
                ...u,
                milliliters: density ? u.grams / density : undefined,
            });
        });

    allUnits[language].filter(u => ['volume'].includes(u.type))
                      .forEach(u => units.push({...u, grams: u.milliliters * density}));

    return units;
}

export function determineScaling(scaling, oldPrimary, newRecipe) {
    if (!scaling && oldPrimary && newRecipe &&
        oldPrimary.meal_type !== 'leftover' && scaling == null) {
        return Math.ceil((oldPrimary.scaling * (oldPrimary.servings ?? 1)) / newRecipe.servings);
    }

    return scaling;
}
